interface Window {
    initialStepsWidth?: number;
    initialStepsHeight?: number;
	TenantSlug: string
}

module LS.Client3DEditor {
    import ObservableValue = spt.Utils.ObservableValue;

    var startDragEvent = { type: 'startDrag' };
    var selectionChangedEvent = { type: 'selectionChanged' };
    var selectionUiChangedEvent = { type: 'selectionUiChanged' };
    var selectionNeedsUpdateEvent = { type: 'selectionNeedsUpdate' };
    var selectionUiNeedsUpdateEvent = { type: 'selectionUiNeedsUpdate' };
    var moduleAppearanceNeedsUpdateEvent = { type: 'moduleAppearanceNeedsUpdate' };
    var changeEvent = { type: 'change' };
    var toolChangedEvent = { type: 'toolChanged' };
    var mouseUpEvent = { type: 'mouseUp' };
    var mouseDownEvent = { type: 'mouseDown' };
    var beforeMouseMoveEvent = { type: 'beforeMouseMove' };
    var mouseMoveEvent = { type: 'mouseMove' };
    var keyDownEvent = { type: 'keyDown' };
    var keyUpEvent = { type: 'keyUp' };
    var dblclickEvent = { type: 'dblclick' };
    var beforeMouseDownEvent = { type: 'beforeMouseDown' };
    var afterMouseDownEvent = { type: 'afterMouseDown', ev: null };
    var applyEntryEvent = { type: 'applyEntry', data: null, source: null };

    export interface IViewLayer {
        Name: string;
        DisplayName: string;
        IconX: number;
        IconY: number;
    }

    export interface ISelectableUi {
        moveBy(v: THREE.Vector3): void;
        removeInstance(): void;
        GetPositionProperty(k: string): number;
        GetSizeProperty(k: string): number;
        isSelected: boolean;
        isHovered: boolean;
        _isDisposed: boolean;
        id: number;
        OnChanged(viewModel?: ViewModel): void;
        applyObjectPosition(): void;
        duplicate(count?: number, distance?: number, direction?: THREE.Vector3): void;
        rotateByPivot(pivot: THREE.Vector3, angleRad: number);
        getPivot(): THREE.Vector3;
    }

    export interface IParameterObject {
        setParameter(name: string, oldValue: any, newValue: any): void;
        getParameter(name: string): any;
        TypeName: string;
    }

    export class ViewModel implements IEventManager {
        ContainerWidth = 200;
        ContainerHeight = 200;

        pressedKeys: number[] = [];
        protected _selectedToolUri: string = null;
        //selected tool without parameters
        selectedTool: string = null;
        //selected tool with parameters
        selectedToolUri: string;
        tools: {
            [name: string]: ITool;
            addSpacingTool: AddSpacingTool;
            conduitTool: ConduitTool;
            stringingTool: StringingTool;
        } = {} as any;
        emptyTool: ITool = new EmptyTool();

        additionalViewmodels: { [name: string]: any } = {};

        isToolboxRowOrientation: boolean;
        isReadOnly: boolean;

        //mouse postion in range [-1, 1]
        readonly mouse: THREE.Vector2;
        readonly startMouse = new THREE.Vector2();

        //current dom mouse position
        readonly viewPosition = new ObservableVector2();
        //dom mouse on last mousedown
        readonly startViewPosition = new ObservableVector2();
        readonly diffViewPosition: ReadonlyVector2Property;

        view: JQuery = null;
        width = 0;
        height = 0;

        mouseDown = false;
        altDown = false;
        ctrlDown = false;
        shiftDown = false;
        spaceDown = false;
        hasFocus = false;
        disableMouseMove = false;
        cancelMouseDown = false;
        needsUpdate = false;

        readonly emptyInstance: InstanceContext = new InstanceContext(spt.Utils.GuidEmpty());
        readonly instances: InstanceContext[] = [];
        private activeInstance: InstanceContext = null;
        currentInstance: InstanceContext;
        private _highlightedInstance: InstanceContext = null;
        highlightedInstance: InstanceContext;
        readonly instancesById: { [id: string]: InstanceContext };

        Cursor: string = 'default';
        FlyoutVisible: boolean = false;
        ShowInverterFlyout: boolean = false;
        //EnableMultiEdit: boolean = false;
        selectInterferencesEnabled = true;//window.isDebugMode && window.currLogin === "janh";
        RoofAreaStartX: string;
        RoofAreaStartY: string;
        RoofAreaDistanceToRoofBorder: string;
        RoofOrientation: number;
        IsElevation: boolean;
        HasShadows: boolean;
        modulesCounter: { [id: string]: number } = {};
        modulesCount = 0;
        scaleLeftToRight: boolean = true;
        scaleBottomToUpper: boolean = true;
        selected: ClientObjectInstance[] = [];
        readonly selectedModules: number;
        selectedUi: ISelectableUi[] = [];
        selectionChanged: boolean = false;
        canSelect = false;
        //selecting via rectangle
        isSelecting = false;
        canDrag = false;
        //dragging instances
        isDragging = false;
        isScaling = false;
        scaleHelperObject: spt.ThreeJs.utils.edgeIntersectResult;
        canSnap = false;
        _snappingDeactivated = false;
        snappingDeactivated: boolean;
        hovered: ClientObjectInstance = null;

        filteredModuleTypes: string[] = [];

        readonly insertableClientObjects: ClientObject[];
         singleInsertableClientObjects: ClientObject[];
        readonly groupedInsertableClientObjects: ClientObject[][];
        readonly scaleInsertableClientObjects: ClientObject[];
        interferenceObjects: IDynamicInterferenceObject[] = [];
        readonly currentInterferenceObjects: IDynamicInterferenceObject[];
        readonly editableObjectsManager: EditableObjectsManager;
        showModuleAppearance = false;
        showRoofAppearance = false;
        showModulePlan = false;
        showEditorSettings = true;
        hideRoofAppearance = false;

        multiInterferenceView: MultiInterferenceObjectView;

        selectedInsertable: ClientObject = null;
        ModuleNominalPower: number;
        enableSnapping = true;
        private _selectionNeedsUpdate = false;
        private _selectionUiNeedsUpdate = false;
        private _moduleAppearanceNeedsUpdate = false;

        get selectionNeedsUpdate() {
            return this._selectionNeedsUpdate;
        }

        set selectionNeedsUpdate(b: boolean) {
            if (b)
                this.triggerEvent(selectionNeedsUpdateEvent);
            this._selectionNeedsUpdate = b;
        }

        get selectionUiNeedsUpdate() {
            return this._selectionUiNeedsUpdate;
        }

        set selectionUiNeedsUpdate(b: boolean) {
            if (b)
                this.triggerEvent(selectionUiNeedsUpdateEvent);
            this._selectionUiNeedsUpdate = b;
        }

        get moduleAppearanceNeedsUpdate() {
            return this._moduleAppearanceNeedsUpdate;
        }

        set moduleAppearanceNeedsUpdate(b: boolean) {
            if (b)
                this.triggerEvent(moduleAppearanceNeedsUpdateEvent);
            this._moduleAppearanceNeedsUpdate = b;
        }

        readonly keyBoardSteps: ObservableValue;
        readonly interferenceLineThickness: ObservableValue;
        readonly modulePower: string;
        //startRoofPosition --> roofPosition
        get diffRoofPosition(): ReadonlyVector3Property {
            return this.currentInstance.diffRoofPosition;
        }
        //diffRoofPosition after snapping
        get diffSnapPosition(): THREE.Vector3 {
            return this.currentInstance.diffSnapPosition;
        }
        get diffScale(): THREE.Vector3 {
            return this.currentInstance.diffScale;
        }
        get diffScalePosition(): THREE.Vector3 {
            return this.currentInstance.diffScalePosition;
        }
        //roof position on last mousedown
        readonly startRoofPosition = new ObservableVector3();
        //current mouse position on roof
        get roofPosition(): ObservableVector3 {
            return this.currentInstance.roofPosition;
        }
        //internal position of selection
        readonly _selectionPosition = new ObservableVector3();
        readonly ViewSelectionPosition: Vector3Property;
        readonly _selectionSize = new ObservableVector3();
        readonly ViewSelectionSize: Vector3Property;
        readonly showScaleXExtensionInputs: boolean;
        readonly showScaleYExtensionInputs: boolean;
        EditorViewMode: string = window.EditorViewMode;
        ViewLayers: IViewLayer[];
        private _currentViewLayerName: string = null;
        CurrentViewLayerName: string;
        CurrentViewLayer: IViewLayer;
        readonly currentRoofLayers: string[];
        measuredLength = 0;
        stepsWidth: number = window.initialStepsWidth || 0;
        stepsHeight: number = window.initialStepsHeight || 0;

        _moduleCellColor = '0b1b3c';
        _moduleFrameColor = 'b5b5b5';
        _moduleBacksheetColor = 'e5e5e5';
        _moduleCellType: ModuleCellType = ModuleCellType.Monocrystalline;
        _moduleCellcols = 6;
        _moduleCellrows = 10;
        _moduleFrameWidth = 20;

        ModuleCellColor: string;
        ModuleFrameColor: string;
        ModuleBacksheetColor: string;
        ModuleCellType: ModuleCellType;
        ModuleCellcols: number;
        ModuleCellrows: number;
        ModuleFrameWidth: number;
        availableCellTypes: string[] = [
            ModuleCellType[ModuleCellType.Monocrystalline],
            ModuleCellType[ModuleCellType.Polycrystalline],
            ModuleCellType[ModuleCellType.Thinfilm],
            ModuleCellType[ModuleCellType.Flat],
            ModuleCellType[ModuleCellType.Custom]
        ];
        availableCellNumbers: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

        readonly roofAppearance: SolarProTool.InstanceholderAppearance;

        align90Degree = false;
        keepDepth = false;

        slopeAdjustOpts: spt.Utils.IFloatFromInputOptions = {
            notImperial: true,
            precision: 3
        };

        valueAdjustOpts: spt.Utils.IFloatFromInputOptions = {
            notImperial: true,
            precision: window.UseImperialSystem || window.usemeteronroof ? 3 : 0,
            from: "mm",
            to: window.UseImperialSystem ? "ft" : (window.usemeteronroof ? "m" : "mm")
        };

        scaleAdjustOpts: spt.Utils.IFloatFromInputOptions = {
            notImperial: true,
            precision: 2,
            scale: 100
        };

        rotateAdjustOpts: spt.Utils.IFloatFromInputOptions = {
            notImperial: true,
            precision: 2
        };
		
		TenantSlug: KnockoutObservable<string>;

        setRotation(o: any, k: string, v: number) {
            var r = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -360,
                    max: 360,
                    applyArithmetic: true,
                    notImperial: true
                });

            o[k] = r;
        }

        setScale(o: any, k: string, v: number) {
            var s = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: 0,
                    max: 1000000,
                    applyArithmetic: true,
                    notImperial: true,
                    scale: 1 / 100
                });

            o[k] = s;
        }

        setLength(o: any, k: string, v: number) {
            var s = spt.Utils.GetFloatFromInputValue("" + v,
                {
                    min: -1000000,
                    max: 1000000,
                    applyArithmetic: true,
                    isFeet: window.UseImperialSystem,
                    notImperial: !window.UseImperialSystem,
                    from: this.valueAdjustOpts.to,
                    to: "mm"
                });

            o[k] = s;
        }

        readonly selectedBounds: ObservableBounds;

        genericParameters: GenericParamsObject = new GenericParamsObject();
        moduleMetaEdit: ModuleMetaEdit = new ModuleMetaEdit();

        constructor() {
            ko.defineProperty(this, 'selectedToolUri', {
                get: () => {
                    return this._selectedToolUri;
                },
                set: (newTool: string) => {
                    var oldTool = this.selectedTool;

                    //seperate params from uri
                    var uri = spt.Utils.SeperateUriParameters(newTool);
                    if (uri != null)
                        newTool = uri.target;

                    if (newTool === oldTool && (uri == null || uri.params == null))
                        return;

                    if (typeof oldTool === "string" && this.tools[oldTool]) {
                        this.tools[oldTool].onDeselect(this);
                    }

                    this._selectedToolUri = uri != null ? uri.uri : newTool;
                    this.selectedTool = newTool;

                    if (typeof newTool === "string" && this.tools[newTool]) {
                        var tool = this.tools[newTool];
                        $(this.view).css('cursor', tool.cursor);
                        tool.onSelect(this, uri != null ? uri.params : null);
                    } else
                        $(this.view).css('cursor', 'default');

                    this.triggerEvent(toolChangedEvent);
                }
            });
			this.TenantSlug = ko.observable(window.TenantSlug);
			
            ko.getObservable(this, 'selectedToolUri').extend({ notify: 'always' });

            ko.defineProperty(this, 'isToolboxRowOrientation', {
                get: () => {
                    let $aoMapDrawer = $('#root-aoMapDrawer');
                    let direction = $aoMapDrawer.css('flex-direction');
                    return direction === 'row';
                }
            });

            ko.getObservable(this, 'isToolboxRowOrientation').extend({ notify: 'always' });

            ko.track(this);

            var roofAppearance: SolarProTool.InstanceholderAppearance = {
                AoBaseColor: -5363199,
                AoFlatColor: -4144960,
                AoBorderColor: -16744448,
                AoAtticaColor: -7829368,
                AoBeamColor: -256,
                AoRafterColor: -1,
                AoAreaBorderColor: -1,
                AoShowWindzoneAreas: true,
                AoShowRafterAndBeams: true,
                AoShowWindZoneStrings: true
            };

            ko.track(roofAppearance);

            Object.keys(roofAppearance).forEach(k => {
                if (k.endsWith("Color")) {
                    var tc = new THREE.Color();
                    ko.defineProperty(roofAppearance, k + "Hex" as any, {
                        get: () => {
                            return tc.set(roofAppearance[k]).getHexString();
                        },
                        set: (v: string) => {
                            if (v && v.length && v.charAt(0) === '#')
                                v = v.substr(1);
                            v = v.toLowerCase();

                            roofAppearance[k] = 255 << 24 ^ (tc.set("#" + v).getHex());
                        }
                    });
                }
            });

            this.roofAppearance = roofAppearance;

            let _mouse = new THREE.Vector2();

            //mouse postion in range [-1, 1]
            ko.defineProperty(this, 'mouse', () => {
                return _mouse.set((this.viewPosition.x / this.width) * 2 - 1, -(this.viewPosition.y / this.height) * 2 + 1);
            });

            this.diffViewPosition = new ReadonlyVector2Property((k) => {
                return this.viewPosition[k] - this.startViewPosition[k];
            });

            ko.defineProperty(this, 'currentInstance',
                {
                    get: () => {
                        return this.activeInstance || (this.instances.length ? this.instances[0] : this.emptyInstance);
                    },
                    set: (v) => {
                        this.activeInstance = v || null;
                        this.highlightedInstance = null;
                    }
                });

            ko.defineProperty(this, 'highlightedInstance',
                {
                    get: () => {
                        return this._highlightedInstance;
                    },
                    set: (v) => {
                        if (this._highlightedInstance)
                            this._highlightedInstance.highlighted = false;

                        this._highlightedInstance = v || null;

                        if (this._highlightedInstance)
                            this._highlightedInstance.highlighted = true;
                    }
                });

            ko.defineProperty(this, 'instancesById', () => {
                var kv: { [id: string]: InstanceContext } = {};
                this.instances.forEach(inst => {
                    kv[inst.Id] = inst;
                });
                return kv;
            });

            //tools
            Object.keys(LS.Client3DEditor).filter((n) => (<any>LS.Client3DEditor[n]).prototype instanceof BaseTool).forEach(n => {
                var nLower = n.charAt(0).toLowerCase() + n.slice(1);
                this.tools[nLower] = new LS.Client3DEditor[n]() as ITool;
            });

            //ModuleFrameColor: string;
            //ModuleBacksheetColor: string;

            ko.defineProperty(this, "currentInterferenceObjects", () => {
                //if (this.EnableMultiEdit)
                //    return this.interferenceObjects;
                if (!this.currentInstance || !this.currentInstance.Id)
                    return [];
                var curId = this.currentInstance.Id.toLowerCase();
                return this.interferenceObjects.filter(o => o && o.BaseParentId === curId);
            });

            this.multiInterferenceView = new MultiInterferenceObjectView(this);

            ko.defineProperty(this, "snappingDeactivated",
                {
                    get: () => this._snappingDeactivated || this.selectedTool === "addSpacingTool",
                    set: (v) => { this._snappingDeactivated = v; }
                });

            ko.defineProperty(this, "insertableClientObjects", () => this.currentInstance.insertableClientObjects);
            ko.defineProperty(this, "scaleInsertableClientObjects", () => this.currentInstance.scaleInsertableClientObjects);
            ko.defineProperty(this, "singleInsertableClientObjects", () => this.currentInstance.insertableClientObjects
                .filter(co => !co.userData.GroupIconInsert)
                .filter(obj => !this.filteredModuleTypes.includes(obj.dataType)));
            ko.defineProperty(this, "groupedInsertableClientObjects", () => {
                return ArrayHelper.groupBy(this.insertableClientObjects.filter(co => co.userData.GroupIconInsert), (co: ClientObject) => co.dataType);
            });
            ko.defineProperty(this, "RoofAreaStartX", { get: () => this.currentInstance.RoofAreaStartX, set: (v) => { this.currentInstance.RoofAreaStartX = v; } });
            ko.defineProperty(this, "RoofAreaStartY", { get: () => this.currentInstance.RoofAreaStartY, set: (v) => { this.currentInstance.RoofAreaStartY = v; } });
            ko.defineProperty(this, "RoofAreaDistanceToRoofBorder", { get: () => this.currentInstance.RoofAreaDistanceToRoofBorder, set: (v) => { this.currentInstance.RoofAreaDistanceToRoofBorder = v; } });
            ko.defineProperty(this, "RoofOrientation", { get: () => this.currentInstance.RoofOrientation, set: (v) => { this.currentInstance.RoofOrientation = v; } });
            ko.defineProperty(this, "IsElevation", { get: () => this.currentInstance.IsElevation, set: (v) => { this.currentInstance.IsElevation = v; } });
            ko.defineProperty(this, "HasShadows", { get: () => this.currentInstance.HasShadows, set: (v) => { this.currentInstance.HasShadows = v; } });
            ko.defineProperty(this, "ModuleNominalPower", { get: () => this.currentInstance.ModuleNominalPower, set: (v) => { this.currentInstance.ModuleNominalPower = v; } });
            ko.defineProperty(this, "currentRoofLayers", () => this.currentInstance.currentRoofLayers);

            ko.defineProperty(this, "ModuleCellColor", {
                get: () => {
                    return this._moduleCellColor;
                },
                set: (v: string) => {
                    if (v && v.length && v.charAt(0) === '#')
                        v = v.substr(1);
                    v = v.toLowerCase();
                    if (this._moduleCellColor !== v) {
                        this._moduleCellColor = v;
                        //this.moduleAppearanceNeedsUpdate = true;
                    }
                }
            });

            ko.defineProperty(this, "ModuleFrameColor", {
                get: () => {
                    return this._moduleFrameColor;
                },
                set: (v: string) => {
                    if (v && v.length && v.charAt(0) === '#')
                        v = v.substr(1);
                    v = v.toLowerCase();
                    if (this._moduleFrameColor !== v) {
                        this._moduleFrameColor = v;
                        //this.moduleAppearanceNeedsUpdate = true;
                    }
                }
            });

            ko.defineProperty(this, "ModuleBacksheetColor", {
                get: () => {
                    return this._moduleBacksheetColor;
                },
                set: (v: string) => {
                    if (v && v.length && v.charAt(0) === '#')
                        v = v.substr(1);
                    v = v.toLowerCase();
                    if (this._moduleBacksheetColor !== v) {
                        this._moduleBacksheetColor = v;
                        //this.moduleAppearanceNeedsUpdate = true;
                    }
                }
            });

            ko.defineProperty(this, "ModuleCellType", {
                get: () => {
                    return ModuleCellType[this._moduleCellType] as any;
                },
                set: (v) => {
                    var t = <any>parseInt(ModuleCellType[<any>v]);
                    if (this._moduleCellType != t) {
                        this._moduleCellType = t;
                        //this.moduleAppearanceNeedsUpdate = true;
                    }
                }
            });

            ko.defineProperty(this, "ModuleCellcols", {
                get: () => {
                    return this._moduleCellcols;
                },
                set: (v) => {
                    var n = parseInt(<any>v);
                    if (this._moduleCellcols != n) {
                        this._moduleCellcols = n;
                        //this.moduleAppearanceNeedsUpdate = true;
                    }
                }
            });

            ko.defineProperty(this, "ModuleCellrows", {
                get: () => {
                    return this._moduleCellrows;
                },
                set: (v) => {
                    var n = parseInt(<any>v);
                    if (this._moduleCellrows != n) {
                        this._moduleCellrows = n;
                        //this.moduleAppearanceNeedsUpdate = true;
                    }
                }
            });

            ko.defineProperty(this, "ModuleFrameWidth", {
                get: () => {
                    return this._moduleFrameWidth;
                },
                set: (v) => {
                    var n = parseFloat(("" + v).replace(",", "."));
                    if (!isNaN(n) && isFinite(n)) {
                        n = Math.min(200, Math.max(0, n));
                        if (this._moduleFrameWidth !== n) {
                            this._moduleFrameWidth = n;
                            //this.moduleAppearanceNeedsUpdate = true;
                        }
                    }
                }
            });

            let _vsp1 = new THREE.Vector3(),
                _b = new ObservableBounds(),
                _box1 = new THREE.Box3(),
                _v1 = new THREE.Vector3(),
                _selectionSize = this._selectionSize;

            ko.defineProperty(this, 'selectedBounds', () => {
                //TODO non-blocking
                var selected = this.selected, len = selected.length;
                if (len) {
                    var selBounds = _box1.makeEmpty();
                    ArrayHelper.iterateArray(selected, (sel) => {
                        sel.updateSelectionBounds(selBounds);
                    });
                    this._selectionPosition.set(selBounds.min.x, selBounds.min.y, selBounds.min.z);

                    _b.set(selBounds.min.x, selBounds.min.y, selBounds.min.z, selBounds.max.x - selBounds.min.x, selBounds.max.y - selBounds.min.y, selBounds.max.z - selBounds.min.z);
                }
                else {

                    var selectedUi = this.selectedUi, len = selectedUi.length;

                    if (len) {

                        var selBounds = _box1.makeEmpty();
                        ArrayHelper.iterateArray(selectedUi, (sel) => {
                            var w = sel.GetSizeProperty("x"),
                                h = sel.GetSizeProperty("y");

                            if (w > 0 || h > 0) {
                                var x = sel.GetPositionProperty("x"),
                                    y = sel.GetPositionProperty("y");

                                selBounds.expandByPoint(_v1.set(x, y, 0));
                                selBounds.expandByPoint(_v1.set(x + w, y + h, 0));
                            }
                        });

                        if (!selBounds.isEmpty()) {
                            this._selectionPosition.set(selBounds.min.x, selBounds.min.y, selBounds.min.z);

                            _b.set(selBounds.min.x, selBounds.min.y, selBounds.min.z, selBounds.max.x - selBounds.min.x, selBounds.max.y - selBounds.min.y, selBounds.max.z - selBounds.min.z);
                        } else {
                            this._selectionPosition.set(0, 0, 0);

                            _b.set(0, 0, 0, 0, 0, 0);
                        }

                    } else {
                        this._selectionPosition.set(0, 0, 0);

                        _b.set(0, 0, 0, 0, 0, 0);
                    }
                }
                this.selectionChanged = true;
                _selectionSize.set(_b.w, _b.h, _b.d)
                return _b;
            });

            ko.defineProperty(this, 'selectedModules', () => {
                return this.selected.reduce((pv, ci) => {
                    if (ci && !ci._isDisposed && ci.clientObject && ci.clientObject.userData)
                        return pv + ci.clientObject.userData.NumModules;
                    return pv;
                }, 0);
            });

            ko.defineProperty(this, 'modulePower', () => {
                var modCount = this.modulesCount;
                return (Math.round((this.ModuleNominalPower * modCount) / 10) / 100) + " kWp (" + modCount + " M)";
            });

            ko.defineProperty(this, 'ViewLayers', () => window.EditorViewLayers[this.EditorViewMode] || []);

            ko.defineProperty(this, 'CurrentViewLayerName',
                {
                    get: () => {
                        return this._currentViewLayerName && this.ViewLayers.some(vl => vl.Name === this._currentViewLayerName)
                            ? this._currentViewLayerName
                            : this.ViewLayers.length
                                ? this.ViewLayers[0].Name
                                : null;
                    },
                    set: (v) => {
                        this._currentViewLayerName = v || null;
                    }
                });

            ko.defineProperty(this, 'CurrentViewLayer', () => this.CurrentViewLayerName ? this.ViewLayers.filter(vl => vl.Name === this.CurrentViewLayerName)[0] : null);

            ko.defineProperty(this, 'showScaleXExtensionInputs', () => {
                return this.selected.length > 0 && this.selected[0].clientObject.canBeScaledX;
            });

            ko.defineProperty(this, 'showScaleYExtensionInputs', () => {
                return this.selected.length > 0 && this.selected[0].clientObject.canBeScaledY;
            });

            ko.track(this);

            ko.getObservable(this, 'EditorViewMode').subscribe(() => {
                this._currentViewLayerName = null;
            });

            //editable position of selection
            this.ViewSelectionPosition = new Vector3Property((k) => {
                //postionable UI selected
                if (this.selectedUi.length) {
                    var min = Infinity, max = -Infinity;
                    for (var i = 0, j = this.selectedUi.length; i < j; i++) {
                        var p = this.selectedUi[i].GetPositionProperty(k);
                        if (p !== null) {
                            min = Math.min(p, min);
                            max = Math.max(p, max);
                        }
                    }
                    if (isFinite(min))
                        return spt.Utils.ApplyNumberconstraints(mmToImperial((max + min) / 2), { precision: 0 });
                    return "-";
                }
                //clientobject instances selected
                return spt.Utils.ApplyNumberconstraints(mmToImperial(this._selectionPosition[k]), { precision: 0 });
            },
                (k, v) => {

                    var val = spt.Utils.ApplyNumberconstraints(ImperialTomm(spt.Utils.ApplyNumberArithmetic(v, false)), { precision: 0 });

                    if (this.selectedUi.length) {
                        var min = Infinity, max = -Infinity;
                        for (var i = 0, j = this.selectedUi.length; i < j; i++) {
                            var p = this.selectedUi[i].GetPositionProperty(k);
                            if (p !== null) {
                                min = Math.min(p, min);
                                max = Math.max(p, max);
                            }
                        }
                        if (isFinite(min)) {
                            _vsp1.set(0, 0, 0);
                            _vsp1[k] = val - ((max + min) / 2);
                        } else {
                            ko.getObservable(this.ViewSelectionPosition, k).notifySubscribers(0);
                            return;
                        }
                    } else {
                        _vsp1.copy(this._selectionPosition);
                        _vsp1[k] = val;
                        _vsp1.sub(this._selectionPosition);
                    }

                    this._selectionPosition[k] = val;

                    Controller.Current.moveSelectedStatic(_vsp1.x, _vsp1.y, _vsp1.z, true, true);

                    //ko.getObservable(this, 'selected').notifySubscribers(this.selected);
                    //ko.getObservable(this._selectionPosition, k).valueHasMutated();
                });

            //editable size of selection

            this.ViewSelectionSize = new Vector3Property((k) => {
                return spt.Utils.ApplyNumberconstraints(mmToImperial(_selectionSize[k]), { precision: 0 });
            },
                (k, v) => {

                    var len = _selectionSize[k];
                    var lenNew = spt.Utils.ApplyNumberconstraints(ImperialTomm(spt.Utils.ApplyNumberArithmetic(v, false)), { precision: 0 });

                    _selectionSize[k] = lenNew;

                    Controller.Current.scaleSelectedStatic(len, lenNew, k === "x" ? (this.scaleLeftToRight ? 1 : -1) : 0, k === "y" ? (this.scaleBottomToUpper ? 1 : -1) : 0);

                });

            if (!$("#refreshPrinfo").length) {
                ko.getObservable(this, 'modulePower').subscribe((v) => {
                    if (window.EditorViewMode === "Anordnung")
                        $('#prinfo_power').text(v);
                });
            }

            ko.getObservable(this, 'stepsWidth').extend({ IntMinMax: { min: 0 }, notify: 'always' });
            ko.getObservable(this, 'stepsHeight').extend({ IntMinMax: { min: 0 }, notify: 'always' });

            //selection handling
            ko.getObservable(this, 'selected').subscribe(this.onSelectedChanged.bind(this), null, "arrayChange");

            ko.getObservable(this, 'selectedUi').subscribe(this.onSelectedUiChanged.bind(this), null, "arrayChange");

            ko.getObservable(this, 'HasShadows').subscribe((v) => {
                Controller.Current.showShadowLegend(v);
            });

            this.keyBoardSteps = new ObservableValue(50, { internalUnit: "mm", viewUnit: UseImperialSystem ? "in" : "mm", precision: 2 });
            this.interferenceLineThickness = new ObservableValue(250, { internalUnit: "mm", viewUnit: UseImperialSystem ? "in" : "mm", precision: 2 });

            //logObservableChanges(this, 'selectedInsertable');
            //logObservableChanges(this, 'canSelect');

            this.editableObjectsManager = new LS.Client3DEditor.EditableObjectsManager(this);
        }

        getCurrentDefaultCursor(): string {
            return this.currentTool.cursor;
        }

        keyPressed(keyCode: number | number[]) {
            if (Array.isArray(keyCode))
                return (<number[]>keyCode).some(function (key) {
                    return this.pressedKeys.indexOf(key) !== -1;
                }, this);
            return this.pressedKeys.indexOf(<number>keyCode) !== -1;
        }

        get currentTool() {
            var selectedTool = this.tool;
            if (selectedTool && this.tools[selectedTool])
                return this.tools[selectedTool];
            return this.emptyTool;
        }

        get tool() {
            return this.selectedTool;
        }

        set tool(value) {
            this.onSetTool(value);
            this.selectedToolUri = value || null;
        }

        domRenderCallback: () => void;

        afterDomRendered() {
            if (this.domRenderCallback)
                this.domRenderCallback();
        }

        toMouseEventCheck(sourceEvent: any, touch: JQueryTouchEvents.TouchEvent): JQueryMouseEventObject {
            sourceEvent.pageX = touch.position.x;
            sourceEvent.pageY = touch.position.y;
            sourceEvent.clientX = touch.position.x;
            sourceEvent.clientY = touch.position.y;
            return sourceEvent;
        }

        bindToView(viewId: string) {
            var self = this,
                view = this.view = $(viewId);

            this.width = view.innerWidth();
            this.height = view.innerHeight();

            $(document)
                .mousedown((e) => {
                    //console.log("document mousedown");
                    self.hasFocus = false;
                    self.onDocumentMouseDown(e as any);
                })
                .keydown(self.DomKeyDown.bind(self))
                .keyup(self.DomKeyUp.bind(self));

            // document
            $('body').tapend((e, touch) => {
                var button = e.which || 1;
                if (button !== 1 || !this.mouseDown)
                    return;

                if ($.isTouchCapable())
                    e = this.toMouseEventCheck(e, touch);
                this.mouseDown = false;
                self.onMouseUp(e as any);
                this.triggerEvent(mouseUpEvent);
                self.afterMouseUp(e as any);
            });

            // mousedown
            view.tapstart((e, touch) => {
                if (e && typeof e.stopPropagation === "function")
                    e.stopPropagation();
                //this.view.focus();
                this.hasFocus = true;
				
                if (LoaderManager.isLoading())
                    return;
				
                if ($.isTouchCapable()) {
                    e = this.toMouseEventCheck(e, touch);
                    var offset = self.view.offset();
                    self.viewPosition.set(e.clientX - offset.left, e.clientY - offset.top);
                    self.triggerEvent(beforeMouseMoveEvent);
                    self.onMouseMove(e);
                    self.triggerEvent(mouseMoveEvent);
                }

                this.triggerEvent(beforeMouseDownEvent);

                if (this.cancelMouseDown) {
                    this.cancelMouseDown = false;
                    return;
                }

                var button = e.which || 1;
                if (button === 1) {
                    this.mouseDown = true;
                    this.startViewPosition.copy(this.viewPosition);
                    this.startMouse.copy(this.mouse);

                    self.onMouseDown(e as any);
                    this.triggerEvent(mouseDownEvent);
                }

                afterMouseDownEvent.ev = e;
                this.triggerEvent(afterMouseDownEvent);
                //this.dispatchEvent({ type: 'afterMouseDown', ev: e });
            });
            view.dblclick(e => {
                //console.log("view.dblclick");
                this.triggerEvent(dblclickEvent);
            });

            view.on('tapmove', (e, touch) => {
                //console.log("viewDom.mousemove");
                if ($.isTouchCapable())
                    e = this.toMouseEventCheck(e, touch);
                var offset = self.view.offset();
                self.viewPosition.set(e.clientX - offset.left, e.clientY - offset.top);
                self.triggerEvent(beforeMouseMoveEvent);
                self.onMouseMove(e);
                self.triggerEvent(mouseMoveEvent);
            });

            //var viewDom = view.get(0);
            //viewDom.addEventListener('mousemove', (e: JQueryMouseEventObject) => {
            //console.log("viewDom.mousemove");
            //}, false);
            //viewDom.addEventListener('touchmove', (e: JQueryMouseEventObject) => {
            //    if (LoaderManager.isLoading())
            //        return;
            //    console.log("viewDom.touchmove");
            //    self.onTouchMove(e);
            //}, false);
            //viewDom.addEventListener('touchstart', (e: JQueryMouseEventObject) => {
            //    if (LoaderManager.isLoading())
            //        return;
            //    console.log("viewDom.touchstart");
            //    self.onTouchStart(e);
            //}, false);
            //document.addEventListener('touchend', (e: JQueryMouseEventObject) => {
            //    if (LoaderManager.isLoading())
            //        return;
            //    console.log("document.touchend");
            //    self.onTouchEnd(e);
            //}, false);
            //document.addEventListener('touchcancel', (e: JQueryMouseEventObject) => {
            //    if (LoaderManager.isLoading())
            //        return;
            //    console.log("document.touchcancel");
            //    self.onTouchEnd(e);
            //}, false);
        }

        DomKeyDown(e: JQueryKeyEventObject) {
            var self = this;

            switch (e.keyCode) {
                case 16: //'Shift':
                    self.shiftDown = true;
                    break;
                case 17:
                    self.ctrlDown = true;
                    break;
                case 18:
                    self.altDown = true;
                    break;
                case 32://'Spacebar':
                    self.spaceDown = true;
                    break;
            }

            self.pressedKeys.push(e.keyCode);

            if (self.hasFocus) {
                if (!LoaderManager.isLoading()) {
                    self.onKeyDown(e);
                    this.currentTool.onKeyDown(this);
                    this.triggerEvent(keyDownEvent);
                }
                e.stopPropagation();
                if (e.keyCode !== 116 && e.keyCode !== 123) //F5 //F12
                    e.preventDefault();
            }
        }

        DomKeyUp(e: JQueryKeyEventObject) {
            var self = this;

            switch (e.keyCode) {
                case 16: //'Shift':
                    self.shiftDown = false;
                    break;
                case 17:
                    self.ctrlDown = false;
                    break;
                case 18:
                    self.altDown = false;
                    break;
                case 32://'Spacebar':
                    self.spaceDown = false;
                    break;
            }
            if (this.keyPressed(e.keyCode)) {
                self.pressedKeys.remove(e.keyCode);
                self.onKeyUp(e);
                this.currentTool.onKeyUp(this);
            }
            this.triggerEvent(keyUpEvent);
        }

        onTouchStart(e: JQueryMouseEventObject) {
            if ((e as any).touches.length === 1) {
                var touch = (e as any).touches[0];
                e.pageX = touch.pageX;
                e.pageY = touch.pageY;
                e.clientX = touch.clientX;
                e.clientY = touch.clientY;
                this.onMouseMove(e);
                ko.tasks.runEarly();
                this.onMouseDown(e);
                e.preventDefault();
            }
        }

        onTouchMove(e: JQueryMouseEventObject) {
            if ((e as any).touches.length === 1) {
                var touch = (e as any).touches[0];
                e.pageX = touch.pageX;
                e.pageY = touch.pageY;
                e.clientX = touch.clientX;
                e.clientY = touch.clientY;
                this.onMouseMove(e);
            }
        }

        onTouchEnd(e: JQueryMouseEventObject) {
            var touches = (e as any).changedTouches && (e as any).changedTouches.length ? (e as any).changedTouches : (e as any).touches;
            if (touches.length) {
                var touch = touches[0];
                e.pageX = touch.pageX;
                e.pageY = touch.pageY;
                e.clientX = touch.clientX;
                e.clientY = touch.clientY;
                this.onMouseMove(e);
                ko.tasks.runEarly();
                this.onMouseUp(e);
            }
        }

        onKeyUp(e: JQueryKeyEventObject) { }

        addListener(type: string, listener: (event: IEventManagerEvent) => void): EventManager { return this; }

        hasListener(type: string, listener: (event: IEventManagerEvent) => void): boolean { return false; }

        removeListener(type?: string, listener?: (event: IEventManagerEvent) => void): void { }

        dispatchEvent(event: IEventManagerEvent): void { }

        triggerEvent(event: IEventManagerEvent): void {
            this.dispatchEvent(event);
            this.onChanged();
        }

        onChanged() {
            this.dispatchEvent(changeEvent);
        }

        findInstanceContext = (() => {
            var localRay = new THREE.Ray(),
                localPoint = new THREE.Vector3();

            return (worldRay: THREE.Ray, point?: THREE.Vector3, minProcessStep = 0) => {
                var dist = Number.POSITIVE_INFINITY,
                    curInst = this.currentInstance;

                this.instances.filter(inst => inst.ProcessStep >= minProcessStep).forEach(inst => {

                    var p = localRay.copy(worldRay).applyMatrix4(inst.worldToLocalTransform).intersectPlane(inst.plane, localPoint);

                    if (p) {
                        var localDist = p.distanceToSquared(localRay.origin);
                        if (localDist < dist && MapDrawing.Point2D.isInsidePolygon(p, inst.instancePolygons[0], 0.01)) {
                            dist = localDist;
                            curInst = inst;
                            if (point) point.copy(p);
                        }
                    }
                });

                return curInst;
            };
        })();

        getInstanceContext(id: string, controller?: LS.Client3DEditor.Controller): InstanceContext {
            if (!id)
                return null;

            //id = id.toLowerCase();

            var instance = this.instancesById[id];//ArrayHelper.firstOrNull(this.instances, (inst) => inst.Id === id);

            if (!instance) {
                instance = new InstanceContext(id, controller);
                this.instances.push(instance);
                instance.addToScene(controller);
            }

            return instance;
        }

        setInstances(instances: InstanceContext[]) {
            this.activeInstance = null;

            ArrayHelper.replaceContent(this.instances, instances).forEach((inst: InstanceContext) => {
                inst.dispose();
            });
        }

        clearInstances() {
            this.activeInstance = null;

            this.instances.splice(0, this.instances.length).forEach((inst: InstanceContext) => {
                inst.dispose();
            });
        }

        onDocumentMouseDown(e: JQueryMouseEventObject) {
            this.hovered = null;
        }

        onMouseDown(e: JQueryMouseEventObject) {
            var enableSelect = this.currentTool.onMouseDown(this) && this.editableObjectsManager.onMouseDown(this) && !this.spaceDown;
            this.isDragging = false;
            this.isScaling = false;
            this.scaleHelperObject = null;
            this.isSelecting = false;
            this.canSelect = enableSelect;
            this.canDrag = enableSelect;
            this.startRoofPosition.copy(this.roofPosition);
            this.startViewPosition.copy(this.viewPosition);
        }

        onMouseUp(e: JQueryMouseEventObject) {
            this.canSelect = this.currentTool.onMouseUp(this) && this.editableObjectsManager.onMouseUp(this) && this.canSelect;
        }

        afterMouseUp(e: JQueryMouseEventObject) {
            this.isDragging = false;
            this.isScaling = false;
            this.scaleHelperObject = null;
            this.isSelecting = false;
        }

        onKeyDown(e: JQueryKeyEventObject) {
            if (this.spaceDown) {
                this.canSelect = false;
                this.canDrag = false;
            }
        }

        onMouseMove(e: JQueryMouseEventObject) {
            //e.preventDefault();
            this.canSelect = this.currentTool.onMouseMove(this) && this.editableObjectsManager.onMouseMove(this) && this.canSelect;

            if (this.mouseDown && this.canDrag && this.diffViewPosition.lengthSq() >= 100)
                this.startDrag();
        }

        onSelectedChanged(changes: { value: ClientObjectInstance, status: string }[]) {
            changes.forEach((change) => {
                var instance = change.value;
                if (!instance._isDisposed) {
                    switch (change.status) {
                        case "deleted":
                            if (instance.isSelected === true) {
                                instance.isSelected = false;
                                instance.OnChanged();
                            }
                            //this.dispatchEvent({ type: 'selectionDeleted', instance: instance });
                            break;
                        case "added":
                            if (instance.isSelected === false) {
                                instance.isSelected = true;
                                instance.OnChanged();
                            }
                            //this.dispatchEvent({ type: 'selectionAdded', instance: instance });
                            break;
                    }
                }
            });

            this.triggerEvent(selectionChangedEvent);
            //this.selectionChanged = true;
            //if (LS && LS.Electric && LS.Electric.InvertersModel)
            //    LS.Electric.InvertersModel.highlightByIds(this.selected.map(s => s.instanceData.Id));
        }

        onSelectedUiChanged(changes: { value: ISelectableUi, status: string }[]) {
            changes.forEach((change) => {
                var instance = change.value;
                if (!instance._isDisposed) {
                    switch (change.status) {
                        case "deleted":
                            if (instance.isSelected === true) {
                                instance.isSelected = false;
                                instance.OnChanged();
                            }
                            //this.dispatchEvent({ type: 'selectionDeleted', instance: instance });
                            break;
                        case "added":
                            if (instance.isSelected === false) {
                                instance.isSelected = true;
                                instance.OnChanged();
                            }
                            //this.dispatchEvent({ type: 'selectionAdded', instance: instance });
                            break;
                    }
                }
            });

            this.triggerEvent(selectionUiChangedEvent);
            //this.selectionChanged = true;
        }

        startDrag() {
            this.canSelect = false;
            this.triggerEvent(startDragEvent);
            this.canDrag = false;
        }

        switchViewLayer(viewLayer: IViewLayer) {
            if (!viewLayer || !viewLayer.Name)
                return;

            this.deselectAll();

            this.CurrentViewLayerName = viewLayer.Name;

            if (this.EditorViewMode === "Electric")
                Controller.Current.SetupElectricView(viewLayer);

            this.currentTool.onViewLayerChanged(this, this.CurrentViewLayerName);
        }

        addToSelection(instances: { id: string | number }[], forceAdd?: boolean, forceRemove?: boolean, selection?: { id: string | number }[]) {
            var selected = selection || this.selected as { id: string | number }[],
                shift = this.shiftDown,
                ctrl = this.ctrlDown;

            if (!instances || !instances.length) {
                if (!shift && !ctrl && !forceAdd && !forceRemove)
                    selected.removeAll();
                return;
            }

            if ((shift && !ctrl) || forceAdd) {
                //add to selection which are not already selected

                let sel = {};

                instances.filter(s => sel[s.id] ? false : sel[s.id] = true);

                selected.forEach(s => {
                    if (sel[s.id])
                        sel[s.id] = false;
                });

                selected.push.apply(selected, instances.filter(s => sel[s.id]));
                //selected.splice.apply(selected, [selected.length, 0].concat(<any>instances.filter(s => sel[s.id])));
            } else if ((shift && ctrl) || forceRemove) {
                //remove

                let sel = {};

                instances.forEach(s => { sel[s.id] = true; });

                selected.remove(s => !!sel[s.id]);
            } else if (ctrl) {
                //remove if already selected. Add if not already selected.

                let sel = {};

                instances.filter(s => sel[s.id] ? false : sel[s.id] = true);

                selected.remove(s => {
                    if (sel[s.id]) {
                        sel[s.id] = false;
                        return true;
                    }
                    return false;
                });

                ko.tasks.runEarly();
                selected.push.apply(selected, instances.filter(s => sel[s.id]));
                //selected.splice.apply(selected, [selected.length, 0].concat(<any>instances.filter(s => sel[s.id])));
            } else {
                //replace selection
                selected.removeAll();
                ko.tasks.runEarly();
                selected.push.apply(selected, instances);
                //selected.splice.apply(selected, ([0, 0] as any[]).concat(instances));
            }
        }

        filterSelection(fn: (o: ClientObjectInstance) => boolean, selection?: ClientObjectInstance[] | THREE.Object3D[]) {
            var selected = <ClientObjectInstance[]>selection || this.selected;
            selected.remove(o => !fn(o));
        }

        hasSelected(id: number) {
            return this.selected.some((s) => { return id === s.id; });
        }

        //update selected after objects moved
        updateSelected() {
            this.selected.forEach((o) => {
                o.OnChanged();
            });
            this.selectedUi.forEach(o => {
                o.OnChanged(this);
            });
            this.selectionUiNeedsUpdate = true;
            this.selectionNeedsUpdate = true;
            //ko.getObservable(this, "selected").valueHasMutated();
            //ko.getObservable(this, "selectedUi").valueHasMutated();
            //ko.valueHasMutated(this, "selected");
            //ko.valueHasMutated(this, "selectedUi");
        }

        checkSelection(): void {
            //make sure selection is valid
            this.selected.remove((o: ClientObjectInstance) => { return !!o._isDisposed || !o.visible; });
            this.selectionNeedsUpdate = true;

        }

        resetModuleAppearance() {
            this._moduleCellColor = '0b1b3c';
            this._moduleFrameColor = 'b5b5b5';
            this._moduleBacksheetColor = 'e5e5e5';
            this._moduleCellType = ModuleCellType.Monocrystalline;
            this._moduleCellcols = 6;
            this._moduleCellrows = 10;
            this._moduleFrameWidth = 20;
            //this.moduleAppearanceNeedsUpdate = true;
            this.saveModuleAppearance(true);
        }

        setModuleAppearance(material: ModuleAppearanceShader) {
            this._moduleCellType = material.cellType;
            this._moduleCellcols = material.cols;
            this._moduleCellrows = material.rows;
            this._moduleCellColor = material.moduleCellColor.getHexString();
            this._moduleFrameColor = material.moduleFrameColor.getHexString();
            this._moduleBacksheetColor = material.moduleBacksheetColor.getHexString();
            this._moduleFrameWidth = material.frameWidth;
        }

        updateModuleAppearance() {
            var objects = Controller.Current.Objects;
            for (var id in objects) {
                var clientObject = objects[id] as ClientObject;
                if (clientObject.SharedMeshes && clientObject.SharedMeshes.length) {
                    for (var i = clientObject.SharedMeshes.length; i--;) {
                        var sharedMesh = clientObject.SharedMeshes[i],
                            material = sharedMesh.material;
                        if (material instanceof THREE.ModuleAppearanceShader) {
                            material.cellType = this._moduleCellType;
                            material.cols = this.ModuleCellcols;
                            material.rows = this.ModuleCellrows;
                            material.frameWidth = this.ModuleFrameWidth;
                            material.moduleCellColor.set('#' + this.ModuleCellColor);
                            material.moduleFrameColor.set('#' + this.ModuleFrameColor);
                            material.moduleBacksheetColor.set('#' + this.ModuleBacksheetColor);
                        } else if (material instanceof THREE.DefaultAppearanceShader) {
                            material.color.set('#' + this.ModuleFrameColor);
                        }
                    }
                }
            }
        }

        saveModuleAppearance(reset?: boolean) {
            if (LoaderManager.isLoading())
                return;

            var col = new THREE.Color('#' + this.ModuleCellColor);
            var framecol = new THREE.Color('#' + this.ModuleFrameColor);
            var backsheetcol = new THREE.Color('#' + this.ModuleBacksheetColor);
            this.moduleAppearanceNeedsUpdate = true;

            SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("ChangeModuleObjectAppearance").Data({ cellType: this._moduleCellType, cellCols: this.ModuleCellcols, cellRows: this.ModuleCellrows, cellColor: col.getHex(), frameColor: framecol.getHex(), backsheetColor: backsheetcol.getHex(), frameWidth: this.ModuleFrameWidth }).CallBack(() => { });
        }

        saveRoofAppearance() {
            if (LoaderManager.isLoading())
                return;
            var roofAppearance = this.roofAppearance;
            var appearance: SolarProTool.InstanceholderAppearance = {
                AoBaseColor: roofAppearance.AoBaseColor,
                AoFlatColor: roofAppearance.AoFlatColor,
                AoBorderColor: roofAppearance.AoBorderColor,
                AoAtticaColor: roofAppearance.AoAtticaColor,
                AoBeamColor: roofAppearance.AoBeamColor,
                AoRafterColor: roofAppearance.AoRafterColor,
                AoAreaBorderColor: roofAppearance.AoAreaBorderColor,
                AoShowWindzoneAreas: roofAppearance.AoShowWindzoneAreas,
                AoShowRafterAndBeams: roofAppearance.AoShowRafterAndBeams,
                AoShowWindZoneStrings: roofAppearance.AoShowWindZoneStrings
            };

            SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("ChangeInstanceholderAppearance").Data({ appearance: appearance }).CallBack(() => {
                Controller.Current.reloadRoofObject(false, false);
            });
        }

        resetRoofAppearance() {
            SolarProTool.Ajax("WebServices/Anordnung3DService.asmx").Call("ChangeInstanceholderAppearance").Data({ appearance: null }).CallBack(() => {
                Controller.Current.reloadRoofObject(false, false);
            });
        }

        deselectAll() {
            this.selected.removeAll();
            this.selectedUi.removeAll();
        }

        setTool(tool: string) {
            this.tool = tool;
        }

        deselectTool() {
            this.setTool(null);
        }

        onSetTool(tool: string) {
            if (this.selectedInsertable != null)
                this.selectedInsertable = null;
            var controller = Controller.Current;
            controller.setTransformControlsEnabled(!tool);
            setBoxhelper(null);
        }

        interOverviewClick() {
            if (this.selectedTool)
                this.deselectTool();
            return true;
        }

        selectInsertable(insertable: ClientObject) {
            this.selectedInsertable = insertable;
            if (insertable) {
                var rectInsertSelected = this.selectedToolUri === 'rectInsert' && (insertable.userOptions & Client3DObectOptions.NoRectInsert) === 0;
                this.selectedToolUri = null;
                if (rectInsertSelected)
                    this.selectedToolUri = 'rectInsert';
                else
                    this.selectedToolUri = 'insertSingle';
            } else {
                this.deselectTool();
            }
        }

        updateModuleCount(id: number, count: number) {
            if (this.modulesCounter[id] !== count) {
                this.modulesCounter[id] = count;
                this.needsUpdate = true;
            }
        }

        update() {
            var instances = this.instances;
            for (var i = instances.length - 1; i >= 0; i--) {
                instances[i].update();
            }
            if (this.needsUpdate) {
                var modCount = 0, modulesCounter = this.modulesCounter;
                for (var id in modulesCounter) {
                    modCount += modulesCounter[id];
                }
                this.modulesCount = modCount;
                this.needsUpdate = false;
            }
            if (this.selectionUiNeedsUpdate) {
                ko.valueHasMutated(this, 'selectedUi');
                //ko.getObservable(this, 'selectedUi').notifySubscribers(this.selectedUi);
                this.selectionUiNeedsUpdate = false;
            }
            if (this.selectionNeedsUpdate) {
                ko.valueHasMutated(this, 'selected');
                //ko.getObservable(this, 'selected').notifySubscribers(this.selected);
                this.selectionNeedsUpdate = false;
            }
            if (this.moduleAppearanceNeedsUpdate) {
                this.updateModuleAppearance();
                this.moduleAppearanceNeedsUpdate = false;
            }
            this.updateSelectionMeasures();
            this.editableObjectsManager.update(this);
        }

        updateSelectionMeasures() {
            if (!this.selectionChanged)
                return;

            this.selectionChanged = false;

            var measureVisualizer = this.currentInstance.measureVisualizer;

            measureVisualizer.clearSteps();

            var selBounds = this.selectedBounds;

            if (selBounds.w || selBounds.h) {
                var _v1 = new THREE.Vector3();

                if (this.isDragging) {

                    var dp = this.diffSnapPosition;

                    measureVisualizer.addStep(_v1.set(selBounds.x, selBounds.y, selBounds.z).add(dp));
                    measureVisualizer.addStep(_v1.set(selBounds.x + selBounds.w, selBounds.y + selBounds.h, selBounds.z + selBounds.d).add(dp));

                } else {
                    measureVisualizer.addStep(_v1.set(selBounds.x, selBounds.y, selBounds.z));
                    measureVisualizer.addStep(_v1.set(selBounds.x + selBounds.w, selBounds.y + selBounds.h, selBounds.z + selBounds.d));
                }
            }

            //var _v1 = new THREE.Vector3();

            //ArrayHelper.iterateArray(this.selected, (sel) => {
            //    var mat = sel.matrixWorld,
            //        bb = sel.geometry.boundingBox;
            //    measureVisualizer.addStep(_v1.copy(bb.min).applyMatrix4(mat));
            //    measureVisualizer.addStep(_v1.copy(bb.max).applyMatrix4(mat));
            //});

            measureVisualizer.update();

        }

        snappingDeactivatedClick() {
            if (this.selectedTool !== "addSpacingTool")
                this.snappingDeactivated = !this.snappingDeactivated;
        }

        GetSnap(source: THREE.Box3 | THREE.Vector3) {
            if (!this.enableSnapping || !source)
                return null;
            var e: ISnapEvent = {
                source: source,
                snap: null,
                type: source instanceof THREE.Box3 ? 'SnapBox' : 'SnapPoint'
            };
            this.triggerEvent(e);
            return e.snap;
        }

        ApplyDiffSnapPosition() {
            var diff = this.diffSnapPosition,
                dx = Math.round(diff.x),
                dy = Math.round(diff.y),
                dz = Math.round(diff.z);

            if (Math.abs(dx) > 0 || Math.abs(dy) > 0) {
                this.selected.forEach((o) => {
                    var pos = o.position;
                    o.setPosition(pos.x + dx, pos.y + dy, pos.z + dz);
                });
                if (this.selectedUi.length) {
                    this.selectedUi.forEach((o) => {
                        o.applyObjectPosition();
                    });
                    Controller.Current.directedAreaHolder.onDirectedAreasChanged(this.selectedUi, false);
                }
            }
        }

        ApplyDiffScalePosition(dt?: THREE.Vector3, ds?: THREE.Vector3) {
            if (!dt)
                dt = this.diffScalePosition;
            if (!ds)
                ds = this.diffScale;

            if (Math.abs(dt.x) > 0 || Math.abs(dt.y) > 0) {
                this.selected.forEach((o) => {
                    var pos = o.position,
                        scale = o.scale;
                    o.setPosition(pos.x + dt.x, pos.y + dt.y, pos.z);
                    o.setSize(scale.x * ds.x, scale.y * ds.y);
                });
                //if (this.selectedUi.length) {
                //    this.selectedUi.forEach((o) => {
                //        o.applyObjectPosition();
                //    });
                //    Controller.Current.directedAreaHolder.onDirectedAreasChanged(this.selectedUi, false);
                //}
            }
        }

        GetCurrentDefaultCursor() {
            var viewModel = this;
            if (viewModel.selectedTool == null)
                return 'default';
            return viewModel.tools[viewModel.selectedTool].cursor;
        }

        private lastClickedIndex = -1;

        onInterListClick(data: IDynamicInterferenceObject, index: number) {
            var viewModel = this,
                lastClickedIndex = this.lastClickedIndex,
                inters = this.interferenceObjects;

            if (viewModel.shiftDown && index !== lastClickedIndex && lastClickedIndex >= 0 && index >= 0 && inters && inters.length > index && inters.length > lastClickedIndex) {
                var step = index < lastClickedIndex ? 1 : -1;
                var selInters: IDynamicInterferenceObject[] = [];
                for (var i = index; i !== lastClickedIndex; i += step) {
                    var inter = inters[i];
                    if (inter && !inter._isDisposed && !inter.locked)
                        selInters.push(inter);
                }

                viewModel.addToSelection(selInters, true, false, <any[]>viewModel.selectedUi);
            } else {
                if ((!data.isSelected || viewModel.shiftDown || viewModel.ctrlDown) && !data.locked && !data._isDisposed)
                    viewModel.addToSelection([data], false, false, <any[]>viewModel.selectedUi);
            }

            this.lastClickedIndex = +index;

            return true; //false := preventDefault()
        }

        changeInterType(newType: string, interference: IDynamicInterferenceObject) {
            if (!interference || interference.Type === newType)
                return;

            var wasSelected = false;

            if (interference.isSelected) {
                this.addToSelection([interference], false, true, <any[]>this.selectedUi);
                ko.tasks.runEarly();
                wasSelected = true;
            }

            var position = new THREE.Vector3(),
                size = new THREE.Vector3();

            interference.positionAndSize(position, size);

            if (size.x <= 0 || size.y <= 0 || size.z <= 0)
                return;

            var pts: THREE.Vector3[] = [
                position.clone().setZ(size.z),
                position.clone().setX(position.x + size.x).setZ(size.z),
                position.clone().setX(position.x + size.x).setY(position.y + size.y).setZ(size.z),
                position.clone().setY(position.y + size.y).setZ(size.z)
            ];

            var baseParentId = interference.BaseParentId;
            var oldId = interference.IdString;
            var oldState = interference.state.clone();
            var newState = InterferenceTool.createInterferenceState(this.currentInstance.Id, newType, pts);

            Controller.Current.interferenceHelper.updateSetInterferenceState(null, baseParentId, newState, (newId) => {
                if (newId) {
                    newState.IdString = newId.toLowerCase();

                    interference.remove();
                    Controller.Current.interferenceHelper.updateSetInterferenceState(interference.IdString, baseParentId, null);

                    var newInterference = Controller.Current.interferenceHelper.createInterferenceObjectFromData(newState);

                    if (wasSelected && !newInterference.isSelected && !newInterference.locked && !newInterference._isDisposed) {
                        this.addToSelection([newInterference], true, false, <any[]>this.selectedUi);
                        var $aoConstrucionSettings = $('#ao-ConstrucionSettings') as any;
                        if ($aoConstrucionSettings.tabs)
                            $aoConstrucionSettings.tabs("option", "active", 1);
                        if (!Controller.Current.viewModel.FlyoutVisible)
                            LS.Client3DEditor.ToolBox.toggleFlyout();
                    }

                    Controller.Current.updateManager.dynamicUpdate(() => {
                        var o = Controller.Current.interferenceHelper.getById(oldId);
                        if (o) {
                            o.remove();
                            Controller.Current.interferenceHelper.updateSetInterferenceState(oldId, baseParentId, null);

                            Controller.Current.interferenceHelper.createInterferenceObjectFromData(newState);
                            Controller.Current.interferenceHelper.updateSetInterferenceState(newId, baseParentId, newState);
                        }
                    }, () => {
                        var o = Controller.Current.interferenceHelper.getById(newId);
                        if (o) {
                            o.remove();
                            Controller.Current.interferenceHelper.updateSetInterferenceState(newId, baseParentId, null);

                            Controller.Current.interferenceHelper.createInterferenceObjectFromData(oldState);
                            Controller.Current.interferenceHelper.updateSetInterferenceState(oldId, baseParentId, oldState);
                        }
                    });
                }
            });
        }

        clickScaleArrow(horizontal: boolean) {
            if (horizontal)
                this.scaleLeftToRight = !this.scaleLeftToRight;
            else
                this.scaleBottomToUpper = !this.scaleBottomToUpper;
        }
    }

    EventManager.apply(ViewModel.prototype);

    export interface ISnapEvent extends IEventManagerEvent {
        source: THREE.Box3 | THREE.Vector3;
        snap: THREE.Vector3;
    }

    export class Vector2Property extends THREE.Vector2 {
        constructor(getter: (name: 'x' | 'y') => number | string, setter: (name: 'x' | 'y', val: number) => void) {
            super();
            ko.defineProperty(this, 'x', {
                get: getter.bind(this, 'x'),
                set: setter.bind(this, 'x')
            });

            ko.defineProperty(this, 'y', {
                get: getter.bind(this, 'y'),
                set: setter.bind(this, 'y')
            });

            ko.track(this);
        }
    }

    export class ReadonlyVector2Property extends THREE.Vector2 {
        constructor(getter: (name: 'x' | 'y') => number | string) {
            super();
            ko.defineProperty(this, 'x', getter.bind(this, 'x'));

            ko.defineProperty(this, 'y', getter.bind(this, 'y'));

            ko.track(this);
        }
    }

    export class Vector3Property extends THREE.Vector3 {
        constructor(getter: (name: 'x' | 'y' | 'z') => number | string, setter: (name: 'x' | 'y' | 'z', val: number) => void) {
            super();
            ko.defineProperty(this, 'x', {
                get: getter.bind(this, 'x'),
                set: setter.bind(this, 'x')
            });

            ko.defineProperty(this, 'y', {
                get: getter.bind(this, 'y'),
                set: setter.bind(this, 'y')
            });

            ko.defineProperty(this, 'z', {
                get: getter.bind(this, 'z'),
                set: setter.bind(this, 'z')
            });

            ko.track(this);
        }
    }

    export class ReadonlyVector3Property extends THREE.Vector3 {
        constructor(getter: (name: 'x' | 'y' | 'z') => number | string) {
            super();
            ko.defineProperty(this, 'x', getter.bind(this, 'x'));

            ko.defineProperty(this, 'y', getter.bind(this, 'y'));

            ko.defineProperty(this, 'z', getter.bind(this, 'z'));

            ko.track(this);
        }
    }

    export class ObservableVector3 extends THREE.Vector3 {
        constructor() {
            super();
            ko.track(this);
        }
    }

    export class ObservableVector2 extends THREE.Vector2 {
        constructor() {
            super();
            ko.track(this);
        }
    }

    export class ObservableBounds {
        constructor(x?: number, y?: number, z?: number, w?: number, h?: number, d?: number) {
            this.set(x, y, z, w, h, d);
            ko.track(this);
        }

        set(x?: number, y?: number, z?: number, w?: number, h?: number, d?: number) {
            this.x = x || 0;
            this.y = y || 0;
            this.z = z || 0;
            this.w = w || 0;
            this.h = h || 0;
            this.d = d || 0;
        }

        x: number;
        y: number;
        z: number;
        w: number;
        h: number;
        d: number;
    }

    export class ObservablePolygon {
        readonly bounds: THREE.Box3;
        readonly array: ObservableVector3[] = [];
        private readonly subscriptions: WeakMap<ObservableVector3, KnockoutSubscription[]> = new WeakMap();

        readonly lines: THREE.Line3[];

        size: THREE.Vector3;
        center: THREE.Vector3;
        position: THREE.Vector3;

        sizeX: number;
        sizeY: number;
        sizeZ: number;

        positionX: number;
        positionY: number;
        positionZ: number;

        scaleX: number;
        scaleY: number;
        scaleZ: number;

        rotation: number;
        height: number;

        private _scaleX = 1;
        private _scaleY = 1;
        private _scaleZ = 1;
        private _rotation = 0;

        resetScale(x?: number, y?: number, z?: number) {
            this._scaleX = x || 1;
            this._scaleY = y || 1;
            this._scaleZ = z || 1;
        }

        resetRotation(v?: number) {
            this._rotation = v || 0;
        }

        constructor() {
            ko.track(this, ["array", "_rotation", "_scaleX", "_scaleY", "_scaleZ"] as any);

            var koArray = ko.getObservable(this, 'array') as KnockoutObservableArray<ObservableVector3>;
            var onPointChanged = () => {
                koArray.valueHasMutated();
            };

            koArray.subscribe((changes: { value: ObservableVector3, status: string }[]) => {
                var subscriptions = this.subscriptions;
                changes.forEach((change) => {
                    var v = change.value;
                    if (v) {
                        switch (change.status) {
                            case "deleted":
                                if (subscriptions.has(v)) {
                                    subscriptions.get(v).forEach(s => { s.dispose(); });
                                    subscriptions.delete(v);
                                }
                                break;
                            case "added":
                                if (!subscriptions.has(v)) {
                                    var subs: KnockoutSubscription[] = [];

                                    subs.push(ko.getObservable(v, 'x').subscribe(onPointChanged));
                                    subs.push(ko.getObservable(v, 'y').subscribe(onPointChanged));
                                    subs.push(ko.getObservable(v, 'z').subscribe(onPointChanged));

                                    subscriptions.set(v, subs);
                                }
                                break;
                        }
                    }

                });
            }, null, "arrayChange");

            var v1 = new THREE.Vector3();

            var bounds = new THREE.Box3();
            var size = new THREE.Vector3();
            var center = new THREE.Vector3();

            ko.defineProperty(this, "bounds", () => {
                return bounds.setFromPoints(koArray());
            });

            ko.defineProperty(this, "lines", () => {
                var points = koArray(),
                    result: THREE.Line3[] = [],
                    pointsCount = points.length;

                for (let i2 = 1, l = pointsCount; i2 < l; i2++) {
                    let i = i2 - 1,
                        p1 = points[i],
                        p2 = points[i2];

                    result.push(new THREE.Line3(p1, p2));

                }

                return result;
            });

            ko.defineProperty(this, "size",
                {
                    get: () => {
                        return this.bounds.getSize(size);
                    },
                    set: (v: THREE.Vector3) => {
                        var newSize = v1.copy(v);
                        var oldSize = this.size;
                        if (newSize.x !== 0 && newSize.y !== 0 && newSize.z !== 0 && oldSize.x !== 0 && oldSize.y !== 0 && oldSize.z !== 0) {
                            var s = newSize.divide(oldSize);
                            var c = this.center;

                            var m = new THREE.Matrix4().makeTranslation(c.x, c.y, c.z).multiply(new THREE.Matrix4().makeScale(s.x, s.y, s.z).multiply(new THREE.Matrix4().makeTranslation(-c.x, -c.y, -c.z)));

                            this.applyMatrix4(m);

                            this._scaleX *= s.x;
                            this._scaleY *= s.y;
                            this._scaleZ *= s.z;
                        }
                    }
                });

            ko.defineProperty(this, "center",
                {
                    get: () => {
                        return this.bounds.getCenter(center);
                    },
                    set: (v: THREE.Vector3) => {
                        var t = v1.copy(v).sub(this.center);
                        this.array.forEach(p => { p.add(t); });
                    }
                });

            ko.defineProperty(this, "position",
                {
                    get: () => {
                        return this.bounds.min;
                    },
                    set: (v: THREE.Vector3) => {
                        var t = v1.copy(v).sub(this.position);
                        this.array.forEach(p => { p.add(t); });
                    }
                });

            ko.defineProperty(this, "sizeX",
                {
                    get: () => {
                        return this.size.x;
                    },
                    set: (newSize: number) => {
                        var size = this.sizeX;
                        var scale = this.scaleX;
                        if (size > 0 && newSize > 0 && scale !== 0) {
                            this.scaleX = scale * newSize / size;
                        }
                    }
                });

            ko.defineProperty(this, "sizeY",
                {
                    get: () => {
                        return this.size.y;
                    },
                    set: (newSize: number) => {
                        var size = this.sizeY;
                        var scale = this.scaleY;
                        if (size > 0 && newSize > 0 && scale !== 0) {
                            this.scaleY = scale * newSize / size;
                        }
                    }
                });

            ko.defineProperty(this, "sizeZ",
                {
                    get: () => {
                        return this.size.z;
                    },
                    set: (newSize: number) => {
                        var size = this.sizeZ;
                        var scale = this.scaleZ;
                        if (size > 0 && newSize > 0 && scale !== 0) {
                            this.scaleZ = scale * newSize / size;
                        }
                    }
                });

            ko.defineProperty(this, "positionX",
                {
                    get: () => {
                        return this.bounds.min.x;
                    },
                    set: (val) => {
                        var t = (+val || 0) - this.bounds.min.x;
                        this.array.forEach(p => { p.setX(p.x + t); });
                    }
                });

            ko.defineProperty(this, "positionY",
                {
                    get: () => {
                        return this.bounds.min.y;
                    },
                    set: (val) => {
                        var t = (+val || 0) - this.bounds.min.y;
                        this.array.forEach(p => { p.setY(p.y + t); });
                    }
                });

            ko.defineProperty(this, "positionZ",
                {
                    get: () => {
                        return this.bounds.min.z;
                    },
                    set: (val) => {
                        var t = (+val || 0) - this.bounds.min.z;
                        this.array.forEach(p => { p.setZ(p.z + t); });
                    }
                });

            ko.defineProperty(this, "scaleX",
                {
                    get: () => {
                        return this._scaleX;
                    },
                    set: (val) => {
                        var newScale = (+val || 0);
                        var oldScale = this._scaleX;
                        if (newScale !== 0 && oldScale !== 0) {
                            var s = newScale / oldScale;
                            var c = this.center;

                            var m = new THREE.Matrix4().makeTranslation(c.x, c.y, c.z).multiply(new THREE.Matrix4().makeScale(s, 1, 1).multiply(new THREE.Matrix4().makeTranslation(-c.x, -c.y, -c.z)));

                            this.applyMatrix4(m);

                            this._scaleX = newScale;
                        }
                    }
                });

            ko.defineProperty(this, "scaleY",
                {
                    get: () => {
                        return this._scaleY;
                    },
                    set: (val) => {
                        var newScale = (+val || 0);
                        var oldScale = this._scaleY;
                        if (newScale !== 0 && oldScale !== 0) {
                            var s = newScale / oldScale;
                            var c = this.center;

                            var m = new THREE.Matrix4().makeTranslation(c.x, c.y, c.z).multiply(new THREE.Matrix4().makeScale(1, s, 1).multiply(new THREE.Matrix4().makeTranslation(-c.x, -c.y, -c.z)));

                            this.applyMatrix4(m);

                            this._scaleY = newScale;
                        }
                    }
                });

            ko.defineProperty(this, "scaleZ",
                {
                    get: () => {
                        return this._scaleZ;
                    },
                    set: (val) => {
                        var newScale = (+val || 0);
                        var oldScale = this._scaleZ;
                        if (newScale !== 0 && oldScale !== 0) {
                            var s = newScale / oldScale;
                            var c = this.center;

                            var m = new THREE.Matrix4().makeTranslation(c.x, c.y, c.z).multiply(new THREE.Matrix4().makeScale(1, 1, s).multiply(new THREE.Matrix4().makeTranslation(-c.x, -c.y, -c.z)));

                            this.applyMatrix4(m);

                            this._scaleZ = newScale;
                        }
                    }
                });

            ko.defineProperty(this, "rotation",
                {
                    get: () => {
                        return this._rotation;
                    },
                    set: (val) => {
                        var newRotation = (+val || 0) % 360;
                        var r = newRotation - this._rotation;
                        if (r !== 0) {
                            var c = this.center;

                            var m = new THREE.Matrix4().makeTranslation(c.x, c.y, c.z).multiply(new THREE.Matrix4().makeRotationZ(r / 180 * Math.PI).multiply(new THREE.Matrix4().makeTranslation(-c.x, -c.y, -c.z)));

                            this.applyMatrix4(m);

                            this._rotation = newRotation;
                        }
                    }
                });

            ko.defineProperty(this, "height",
                {
                    get: () => {
                        return this.array.reduce((c: number, v) => v.z > c ? v.z : c, -Infinity);
                    },
                    set: (val) => {
                        var v = +val || 0;
                        this.array.forEach(p => { p.setZ(v); });
                    }
                });
        }

        subscribe(callback: () => void) {
            return ko.getObservable(this, 'array').subscribe(callback);
        }

        applyMatrix4(m: THREE.Matrix4) {
            this.array.forEach(p => { p.applyMatrix4(m); });
        }

        removeAll() {
            this.array.removeAll();
        }

        push(p: { x: number, y: number, z?: number } | number, py?: number, pz?: number) {
            var x: number, y: number, z: number;

            if (typeof p === 'number') {
                x = p || 0;
                y = py || 0;
                z = pz || 0;
            } else {
                x = p.x || 0;
                y = p.y || 0;
                z = p.z || 0;
            }

            this.array.push(new ObservableVector3().set(x, y, z));
        }

        getData(): number[] {
            var result: number[] = [],
                points = this.array;

            for (var i = 0, l = points.length; i < l; i++) {
                var p = points[i];
                result.push(p.x, p.y, p.z);
            }

            return result;
        }

        setLength(length: number) {
            var array = this.array;
            if (array.length !== length) {
                if (array.length > length) {
                    array.splice(0, array.length - length);
                } else {
                    while (array.length < length) {
                        array.push(new ObservableVector3());
                    }
                }
            }
        }

        setAsCircle(c: THREE.Vector3, p: THREE.Vector3) {
            if (!c || !p)
                return;

            var z = c.z || p.z || 0,
                radius = c.setZ(0).distanceTo(p.setZ(0));

            var numPoints = Math.floor(Math.min(32, Math.max(8, radius * 0.2))),
                array = this.array;

            if (array.length !== numPoints)
                this.setLength(numPoints);

            var cx = c.x,
                cy = c.y,
                tx = p.x - cx,
                ty = p.y - cy,
                pi2 = Math.PI * 2;

            for (var i = 0; i < numPoints; i++) {
                var rad = pi2 * i / numPoints,
                    sin: number = Math.sin(rad),
                    cos: number = Math.cos(rad),
                    xnew: number = tx * cos - ty * sin,
                    ynew: number = tx * sin + ty * cos;

                array[i].set(cx + xnew, cy + ynew, z);
            }

        }

        setAsRectangle(p1: { x: number, y: number, z?: number }, p2: { x: number, y: number, z?: number }) {
            if (!p1 || !p2)
                return;
            var z = p1.z || p2.z || 0;

            var array = this.array;

            if (array.length !== 4)
                this.setLength(4);

            var minx = Math.min(p1.x, p2.x),
                miny = Math.min(p1.y, p2.y),
                maxx = Math.max(p1.x, p2.x),
                maxy = Math.max(p1.y, p2.y);

            array[0].set(minx, miny, z);
            array[1].set(maxx, miny, z);
            array[2].set(maxx, maxy, z);
            array[3].set(minx, maxy, z);
        }

        setAsTriangle(p1: { x: number, y: number, z?: number }, p2: { x: number, y: number, z?: number }) {
            if (!p1 || !p2)
                return;
            var z = p1.z || p2.z || 0;

            var array = this.array;

            if (array.length !== 3)
                this.setLength(3);

            array[0].set(p1.x, p1.y, z);
            array[1].set(p2.x, p2.y, z);
            array[2].set(p1.x + p1.x - p2.x, p2.y, z);
        }

        setAsThickLine(pts: { x: number, y: number, z?: number }[], width: number) {
            //if (!pts || width < 0)
            //    return;

            //var precision = 100,
            //    c = new ClipperLib.ClipperOffset();
            //c.AddPath(spt.ThreeJs.utils.PointsToPath(pts, precision), ClipperLib.JoinType.jtRound, ClipperLib.EndType.etOpenButt);
            //var expanded = new ClipperLib.PolyTree();
            //c.Execute(expanded, width * precision);

            //var newPts = spt.ThreeJs.utils.PathsToPoints(expanded, precision)[0];

            //THREE.ShapeUtils.triangulateShape(poly.array.slice(), [])

            //var array = this.array,
            //    len = pts.length;

            //if (array.length !== len)
            //    this.setLength(len);
        }

        getBoundingSphere() {
            var sphere = new THREE.Sphere(),
                box = new THREE.Box3().setFromPoints(this.array);

            box.min.setZ(Math.min(box.min.z, 0));

            var center = box.getCenter(sphere.center);

            var maxRadiusSq = 0;

            this.array.forEach(p => {
                maxRadiusSq = Math.max(maxRadiusSq, center.distanceToSquared(p));
            });

            sphere.radius = Math.sqrt(maxRadiusSq) + 1;

            return sphere;
        }

    }

    export class ClientGenericParamsEntry implements IClientGenericParamsEntry {
        name: string = "";
        value: any;
        _value: any = null;
        oldValue: any;
        displayName: string = null;
        displayNameInfo: string = null;
        min: number = null;
        max: number = null;
        precision: number = null;
        inputType: string = null;
        source: string = null;
        enableOn: string = null;
        hidden: boolean = false;
        disabled: boolean = false;
        isOverload: boolean = false;
        isAngle: boolean = false;
        isArray: boolean = false;
        expanded: boolean = false;
        subscriptions: KnockoutSubscription[];
        subscriptionsNeedsUpdate: boolean;

        constructor(p: IClientGenericParamsEntry) {

            if (p && typeof p === "object")
                Object.keys(p).filter(k => this.hasOwnProperty(k)).forEach(k => { this[k] = p[k]; });

            if (p && ((p.value && Array.isArray(p.value)) || p.isArray)) {

                this.isArray = true;

                this._value = [] as KnockoutObservable<any>[];

                ko.track(this);

                ko.getObservable(this, '_value').subscribe(this.onArrayChange.bind(this), null, "arrayChange");

                ko.defineProperty(this, 'value', {
                    get: () => {
                        return this._value;
                    },
                    set: (vals: any[]) => {
                        this._value.removeAll();
                        if (vals && vals.length)
                            vals.forEach(val => this._value.push(ko.observable(this.processValue(ko.unwrap(val)))));
                    }
                });

                if (p.value && p.value.length)
                    this.value = p.value;

                this.onArrayChange();

            } else {
                ko.track(this);

                ko.defineProperty(this, 'value', {
                    get: () => {
                        return this._value;
                    },
                    set: (value) => {
                        var val = this.processValue(value);
                        this.oldValue = this._value;
                        this._value = val;
                    }
                });

                ko.getObservable(this, 'value').extend({ notify: 'always' });
                ko.getObservable(this, '_value').extend({ notify: 'always' });

                if (p && p.value !== undefined)
                    this.value = p.value;

                this.oldValue = this.value;
            }
        }

        unwrapValue(): any {
            return this.isArray ? this.value.map(v => ko.unwrap(v)) : this.value;
        }

        onArrayChange() {
            if (!this.subscriptionsNeedsUpdate) {
                this.subscriptionsNeedsUpdate = true;
                setTimeout(this.updateSubscriptions.bind(this), 0);
            }
        }

        updateSubscriptions() {
            if (this.subscriptionsNeedsUpdate) {

                if (this.subscriptions)
                    this.subscriptions.forEach(s => s.dispose());

                var valueArray = this._value as KnockoutObservable<any>[];

                if (valueArray && valueArray.length)
                    this.subscriptions = valueArray.map((val, i) => val.subscribe(this.onArrayElementChange.bind(this, i)));
                else
                    this.subscriptions = [];

                this.subscriptionsNeedsUpdate = false;

                if (LS.Client3DEditor && LS.Client3DEditor.Controller && LS.Client3DEditor.Controller.Current)
                    LS.Client3DEditor.Controller.Current.notifyRefreshView();
            }
        }

        onArrayElementChange(index: number, val: any) {
            this._value[index](this.processValue(val));
            ko.valueHasMutated(this, '_value');
        }

        processValue(value?: any): any {
            var val = value !== undefined ? value : null;

            switch (this.inputType) {
                case "float":
                case "int":
                    {
                        if (typeof val !== "number" && typeof val !== "string")
                            val = 0;
                        val = spt.Utils.ApplyNumberArithmetic(val, false, true);
                        if (this.isAngle)
                            val = spt.Utils.ClampAngle(val);
                        val = spt.Utils.ApplyNumberconstraints(val, this);
                        if (this.inputType === "int")
                            val = Math.round(val);
                    }
                    break;
                case "select":
                    {

                    }
                    break;
                case "checkbox":
                    {
                        val = !!value;
                    }
                    break;
                case "string":
                    {
                        if (typeof val !== "string")
                            val = "";
                    }
                    break;
            }

            return val;
        }
    }

    export interface IEntrySetting {
        name: string;
        typeName: string;
        entries: IClientGenericParamsEntry[];
    }

    export class EntrySetting implements IEntrySetting {
        constructor(entrySetting?: IEntrySetting) {

            if (entrySetting) {
                this.name = entrySetting.name;
                this.typeName = entrySetting.typeName;
                this.default = entrySetting.entries;
                this.entries = entrySetting.entries.map(e => new ClientGenericParamsEntry(e));
            }

            ko.track(this);
        }

        name: string = "";

        typeName: string = "";

        entries: ClientGenericParamsEntry[] = [];

        default: IClientGenericParamsEntry[] = [];

        getSettingsObject() {
            var s = {};
            for (var i = this.entries.length; i--;) {
                var e = this.entries[i];
                if (!e.isOverload)
                    s[e.name] = e.unwrapValue();
            }
            return s;
        }

        getDefaultSettingsObject() {
            var s = {};
            for (var i = this.default.length; i--;) {
                var e = this.default[i];
                s[e.name] = e.value;
            }
            return s;
        }
    }

    export class GenericParamsObject implements IEventManager {

        constructor() {
            ko.track(this);

            ko.getObservable(this, "entrySetting").subscribe((pe: EntrySetting) => {
                if (pe) {
                    this.setEntries(pe.entries);
                    if (!this.lock) {
                        if (this.objs.length)
                            this.applyEntrySettingDefault(pe);
                        else
                            this.updateEntries();
                    }
                } else
                    this.setEntries();
            });

            ko.defineProperty(this, "selectedElements", () => {
                return this.overrideElementsCount >= 0 ? this.overrideElementsCount : this.objs.length;
            });
        }

        applyEntrySettingDefault(pe: EntrySetting) {
            if (this.objs.length) {
                var so = pe.getDefaultSettingsObject();

                this.objs.forEach(obj => {
                    if (obj["_myDirectedArea"]) {
                        var da = obj["_myDirectedArea"] as spt.ThreeJs.utils.DirectedAreaDefinition;
                        da.userData = so;
                    }
                });

                applyEntryEvent.data = this.objs;
                applyEntryEvent.source = "TypeName";

                this.dispatchEvent(applyEntryEvent);
                //this.dispatchEvent({ type: 'applyEntry', data: this.objs as any, source: "TypeName" });
            }
        }

        private possibleEntrySettings: EntrySetting[] = [];
        entrySetting: EntrySetting = null;
        private subscriptions: KnockoutSubscription[];
        private overloadSubscriptions: KnockoutSubscription[];
        private entries: ClientGenericParamsEntry[] = [];
        private objs: LS.Client3DEditor.IParameterObject[] = [];
        private lock = false;
        overrideElementsCount: number = -1;
        selectedElements: number;

        resetToDefault() {
            if (this.entrySetting && this.objs.length)
                this.applyEntrySettingDefault(this.entrySetting);
        }

        getCurrentEntrySetting() {
            if (this.entrySetting)
                return this.entrySetting;
            if (this.possibleEntrySettings.length)
                return this.possibleEntrySettings[0];
            return null;
        }

        getEntrySettingByTypeName(typeName: string) {
            for (var i = 0, j = this.possibleEntrySettings.length; i < j; i++) {
                var pe = this.possibleEntrySettings[i];
                if (pe.typeName === typeName)
                    return pe;
            }
            return null;
        }

        bindObject(obj: any) {
            this.objs.push(obj);
            if (this.objs.length <= 1)
                this.updateEntries();
        }

        unbindObject(obj: any) {
            var countBefore = this.objs.length;
            var doUpdate = countBefore < 1 || this.objs[0] === obj;
            this.objs.remove(obj);
            if (doUpdate && countBefore !== this.objs.length)
                this.updateEntries();
        }

        bindObjects(objs: any[]) {
            this.objs.splice.apply(this.objs, [0, 0].concat(objs));
            this.updateEntries();
        }

        unbindAll() {
            this.objs.removeAll();
            this.updateEntries();
        }

        updateEntries() {
            this.lock = true;

            var obj = this.objs.length ? this.objs[0] : null;

            if (obj) {
                if (obj.TypeName && (!this.entrySetting || this.entrySetting.typeName !== obj.TypeName))
                    this.entrySetting = this.getEntrySettingByTypeName(obj.TypeName);

                this.entries.forEach(e => {
                    var val = e.isOverload ? obj.getParameter(e.name) : obj[e.name];

                    if (val !== undefined && val !== null) {
                        e.disabled = false;
                        e.value = val;
                    } else
                        e.disabled = true;
                });
            } else
                this.entries.forEach(e => {
                    e.disabled = true;
                    e.expanded = false;
                });

            ko.tasks.runEarly();

            this.lock = false;
        }

        applyEntries(entries: ClientGenericParamsEntry[]) {
            if (this.lock || !entries || !entries.length)
                return;

            entries = entries.filter(entry => !(entry.disabled || entry.hidden || !entry.name));

            if (!entries.length)
                return;

            this.objs.forEach(obj => {
                entries.forEach(entry => {
                    if (obj[entry.name] !== undefined)
                        obj[entry.name] = entry.unwrapValue();
                });
            });

            applyEntryEvent.data = this.objs.slice();
            applyEntryEvent.source = "TypeName";

            this.dispatchEvent(applyEntryEvent);
            //this.dispatchEvent({ type: 'applyEntry', data: this.objs.slice(), source: "TypeName" });
        }

        applyEntry(entry: ClientGenericParamsEntry) {
            if (this.lock || entry.disabled || entry.hidden || !entry.name)
                return;

            var objs: any[];

            if (entry.isOverload)
                objs = this.objs.slice();
            else {
                objs = [];

                this.objs.forEach(obj => {
                    if (obj[entry.name] !== undefined) {
                        obj[entry.name] = entry.unwrapValue();
                        objs.push(obj);
                    }
                });
            }

            applyEntryEvent.data = objs;
            applyEntryEvent.source = entry;

            this.dispatchEvent(applyEntryEvent);
            //this.dispatchEvent({ type: 'applyEntry', data: objs, source: entry });
        }

        setOverloadEntries(entries: IClientGenericParamsEntry[]) {
            this.entries.removeAll(this.entries.filter(e => e.isOverload));

            if (this.overloadSubscriptions && this.overloadSubscriptions.length)
                this.overloadSubscriptions.forEach(s => { s.dispose(); });

            this.overloadSubscriptions = [];

            if (entries) {
                var newEntries = entries.map(e => {
                    var ne = e instanceof ClientGenericParamsEntry ? e : new ClientGenericParamsEntry(e);
                    ne.isOverload = true;
                    return ne;
                });

                newEntries.forEach(e => {
                    this.overloadSubscriptions.push(ko.getObservable(e, "_value").subscribe(this.applyEntry.bind(this, e)));
                });

                this.entries.splice.apply(this.entries, [0, 0].concat(newEntries as any[]));
            }
        }

        setEntries(entries?: IClientGenericParamsEntry[]) {
            this.entries.removeAll(this.entries.filter(e => !e.isOverload));

            if (this.subscriptions && this.subscriptions.length)
                this.subscriptions.forEach(s => { s.dispose(); });

            this.subscriptions = [];

            if (entries && entries.length) {
                var newEntries = entries.map(e => new ClientGenericParamsEntry(e));

                newEntries.forEach(e => {
                    this.subscriptions.push(ko.getObservable(e, "_value").subscribe(this.applyEntry.bind(this, e)));
                });

                this.entries.push.apply(this.entries, newEntries);
            }

        }

        setPossibleEntrySettings(entrySettings: IEntrySetting[]) {
            this.unbindAll();

            if (this.entrySetting)
                this.entrySetting = null;

            this.possibleEntrySettings.removeAll();

            if (entrySettings && entrySettings.length)
                this.possibleEntrySettings.push.apply(this.possibleEntrySettings, entrySettings.map(es => new EntrySetting(es)));

            if (this.possibleEntrySettings.length)
                this.entrySetting = this.possibleEntrySettings[0];

            this.updateEntries();
        }

        GetByName(name: string) {
            for (var i = this.entries.length; i--;) {
                var e = this.entries[i];
                if (e.name === name)
                    return e;
            }
            return null;
        }

        GetValueByName(name: string) {
            for (var i = this.entries.length; i--;) {
                var e = this.entries[i];
                if (e.name === name)
                    return e.value;
            }
            return null;
        }

        ToggleValueVisibleBtnClick(d: any) {
            var entry = d.data as ClientGenericParamsEntry;
            entry.expanded = !entry.expanded;
        }

        AddValueBtnClick(d: any) {
            var entry = d.data as ClientGenericParamsEntry;
            if (entry.isArray) {
                var arr = entry.value as any[];
                var newVal = arr.length ? ko.unwrap(arr[arr.length - 1]) : entry.processValue();
                arr.push(ko.observable(newVal));
                //this.applyEntry(entry);
            }
        }

        RemoveValueBtnClick(index: number, d: any) {
            var entry = d.data as ClientGenericParamsEntry;
            if (entry.isArray && index >= 0 && index < entry.value.length) {
                var arr = entry.value as any[];
                arr.splice(index, 1);
                //this.applyEntry(entry);
            }
        }

        addListener(type: string, listener: (event: IEventManagerEvent) => void): EventManager { return this; }

        hasListener(type: string, listener: (event: IEventManagerEvent) => void): boolean { return false; }

        removeListener(type?: string, listener?: (event: IEventManagerEvent) => void): void { }

        dispatchEvent(event: IEventManagerEvent): void { }
    }

    EventManager.apply(GenericParamsObject.prototype);

    export class ModuleMetaEdit {

        constructor() {
            ko.track(this);
        }

        moduleIds: string[] = [];
        genericParams: GenericParamsObject = new GenericParamsObject();
        metaType: string = null;
        params: any = null;
    }
}

module LS.Client3DEditor {
    //see Client3DObject_Generators.cs
    export var DefaultRenderOrder = 0;
    export var OverlayRenderOrder = 2;
    export var UIRenderOrder = 11;
    export var scrollbarWidth = 0;
    export var toolbarWidthRow = 0;

    export var globalParams = {
        ViewId: "#aoes5-view",
        ContainerId: "#aoes5-view-container",
        TBId: "#aoes5-tb",
        TBContainerId: "#aoes5-tb-container",
        ContentHeightClass: ".aoes5-content-height",
        ContentId: "#content",
        WrapperId: "#aoes5-wrapper",
        StatusbarId: "#ao-Statusbar",
        CameraPadding: 10,
        ClickTolerance: 15, //pixel
        ContextMenu: "#clientEditor-contextmenu",
        DeferredRendering: true
    };

    export function setBoxhelper(object?: THREE.Object3D) {
        var controller = Controller.Current,
            boxHelper = controller.boxHelper,
            edgeHighlightHelper = controller.edgeHighlightHelper;

        if (object) {
            if (!boxHelper.visible)
                boxHelper.visible = true;

            boxHelper.setFromObject(object);

            if (object instanceof LS.Client3DEditor.ClientObjectInstance)
                boxHelper.userData.Id = object.instanceData.Id;
            else
                boxHelper.userData.Id = null;

            if (!controller.viewModel.tool)
                setScalehelper(object);
        } else {
            if (boxHelper.visible)
                boxHelper.visible = false;

            boxHelper.userData.Id = null;

            if (!controller.viewModel.tool && edgeHighlightHelper.visible)
                edgeHighlightHelper.visible = false;
        }
    }

    export function setScalehelper(object?: THREE.Object3D, localRaycaster?: THREE.Raycaster) {
        var controller = Controller.Current,
            edgeHighlightHelper = controller.edgeHighlightHelper,
            isVisible = false,
            edgeInter: spt.ThreeJs.utils.edgeIntersectResult = null;

        if (!localRaycaster)
            localRaycaster = controller.localRaycaster;

        edgeHighlightHelper.userData.Id = null;

        if (object && object instanceof LS.Client3DEditor.ClientObjectInstance && object.clientObject.canBeScaled && controller.viewModel.selected.some(sel => sel.instanceData.Id === object.instanceData.Id) && (edgeInter = spt.ThreeJs.utils.raycasterIntersectEdge(localRaycaster, object))) {

            var { bounds, direction, center } = edgeInter;
            edgeHighlightHelper.start.copy(center);
            edgeHighlightHelper.end.copy(center);

            if (Math.abs(direction.x) > Math.abs(direction.y)) {
                isVisible = object.clientObject.canBeScaledX;
                edgeHighlightHelper.start.setX(bounds.min.x);
                edgeHighlightHelper.end.setX(bounds.max.x);
                edgeHighlightHelper.thickness = bounds.max.y - bounds.min.y;
            } else {
                isVisible = object.clientObject.canBeScaledY;
                edgeHighlightHelper.start.setY(bounds.min.y);
                edgeHighlightHelper.end.setY(bounds.max.y);
                edgeHighlightHelper.thickness = bounds.max.x - bounds.min.x;
            }

            edgeHighlightHelper.zThickness = bounds.max.z - bounds.min.z;

            edgeHighlightHelper.userData.Id = object.instanceData.Id;

        }

        edgeHighlightHelper.visible = isVisible;
        if (isVisible)
            edgeHighlightHelper.onChanged();

        return isVisible ? edgeInter : null;
    }

    export function getScrollBarWidth() {
        var inner = document.createElement('p');
        inner.style.width = "100%";
        inner.style.height = "200px";

        var outer = document.createElement('div');
        outer.style.position = "absolute";
        outer.style.top = "0px";
        outer.style.left = "0px";
        outer.style.visibility = "hidden";
        outer.style.width = "200px";
        outer.style.height = "150px";
        outer.style.overflow = "hidden";
        outer.appendChild(inner);

        document.body.appendChild(outer);
        var w1 = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        var w2 = inner.offsetWidth;

        if (w1 == w2) {
            w2 = outer.clientWidth;
        }

        document.body.removeChild(outer);

        return (w1 - w2);
    }

    export function updateToolboxDropdownMenuOrientation() {
        let $toolbox = $('#ao-Toolbox'),
            $aoMapDrawer = $('#root-aoMapDrawer');

        let direction = $aoMapDrawer.css('flex-direction');

        let dropdownMenus = $toolbox.find('.dropdown-toolbar-custom');
        dropdownMenus.removeClass('dropdown dropright');
        if (direction === 'row')
            dropdownMenus.addClass('dropright');
        else
            dropdownMenus.addClass('dropdown');
    }

    export function onWindowResize() {
        var controller = Controller.Current;
        if (!controller)
            return;

        var viewModel = controller.viewModel,
            $content = $(controller.ContentId),
            $aoes5View = controller.$aoes5View,
            height = $content.innerHeight(),
            $toolbox = $('#ao-Toolbox'),
            $aoMapDrawer = $('#root-aoMapDrawer');



        //var $curr = $aoes5View,
        //    hSub = (parseInt($curr.css('padding-top')) || 0) + (parseInt($curr.css('padding-bottom')) || 0) + (parseInt($curr.css('margin-top')) || 0) + (parseInt($curr.css('margin-bottom')) || 0),
        //    it = 0;
        //do {
        //    $curr = $curr.parent();
        //    if (it++ > 8 || $curr.is(document as any))
        //        break;
        //    hSub += (parseInt($curr.css('padding-top')) || 0) + (parseInt($curr.css('padding-bottom')) || 0) + (parseInt($curr.css('margin-top')) || 0) + (parseInt($curr.css('margin-bottom')) || 0);
        //} while (!$curr.is($content));

        //height -= hSub += $(controller.StatusbarId).outerHeight();
        //if (height < 20)
        //    height = 20;
        ////height += LS.Client3DEditor.Controller.viewPaddingY;

        //$(controller.ContentHeightClass).height(height);

        //var $container = $(controller.ContainerId),
        //    containerOffset = $container.offset(),
        //    padding = parseInt($('#content_td').css('padding-right')) || 0,
        //    w = $(window).width() - padding - containerOffset.left + LS.Client3DEditor.Controller.viewPaddingX,
        //    h = $aoes5View.innerHeight() + LS.Client3DEditor.Controller.viewPaddingY;

        //w = $(window).width() - containerOffset.left - 2 - 14; // IMPORTANT!!! -2 because of toolbar border left and right; -14 because of scrollbar if height is too low
        //h = $aoes5View.innerHeight();

        updateToolboxDropdownMenuOrientation();

        let direction = $aoMapDrawer.css('flex-direction');
        //let w = viewModel.view.innerWidth();
        //let h = viewModel.view.innerHeight();
        let w = $aoMapDrawer.innerWidth();
        let h = $aoMapDrawer.innerHeight() - $("footer").innerHeight(); //$(controller.ContainerId).innerHeight();

        // quick bugfix on SPT.Eco stepper back, prevent set wrong width or height
        if (w <= 0 || h <= 0)
            return;

        let toolbarHeight = 0;
        // calculate size of toolbar
        if ($toolbox.length !== 0 && toolbarWidthRow === 0) {
            let width = $toolbox.innerWidth();
            let height = $toolbox.innerHeight();
            toolbarWidthRow = width < height ? width : height;
        }
        if (direction !== 'row') {
            let border = 6;
            toolbarHeight = $toolbox.length === 0 ? 0 : $toolbox.innerHeight() + border;
        }

        if (scrollbarWidth === 0)
            scrollbarWidth = getScrollBarWidth();
        let toolboxMaxWidth = 0;

        // TODO bug $toolbox.get(0).scrollHeight => Cannot read property 'scrollHeight' of undefined
        let isScrollbarAvailable = $toolbox.length === 0 ?
            false :
            $toolbox.get(0).scrollHeight > Math.ceil($toolbox.height()); // ceil IMPORTANT!

        if (direction === 'row') {
            toolboxMaxWidth += toolbarWidthRow;
            if (isScrollbarAvailable)
                toolboxMaxWidth += scrollbarWidth;
        }
        else {
            h -= toolbarHeight;
        }
        w -= toolboxMaxWidth;

        //$aoes5View.width(w);
        //$container.width(w);

        viewModel.ContainerWidth = w;
        viewModel.ContainerHeight = h;
        viewModel.width = w;
        viewModel.height = h;

        //Shadow Legend Height
        //$('#aoShadowLegend').height(h);
        //Toolbox Height
        //$('#ao-Toolbox').height($container.outerHeight());

        var camera = controller.camera,
            uicamera = controller.uicamera,
            controls = controller.controls,
            controlHelper = controller.controlHelper,
            w2 = w / 2.0,
            h2 = h / 2.0;

        controls.reset(undefined, undefined, -w2, w2, h2, -h2);
        controls.handleResize();

        controller.renderer.setSize(w, h);
        controlHelper.UpdateOrientation();

        if (camera instanceof THREE.PerspectiveCamera) {
            camera.aspect = w / h;
            //camera.aspect = window.innerWidth / window.innterHeight;
            //camera.updateProjectionMatrix();
            //renderer.setSize(window.innerWidth, window.innterHeight);
        }

        camera.updateProjectionMatrix();

        uicamera.left = -w2;
        uicamera.right = w2;
        uicamera.top = h2;
        uicamera.bottom = -h2;
        uicamera.updateProjectionMatrix();

        spt.ThreeJs.utils.LineMaterialHelper.updateViewSize(viewModel.ContainerWidth, viewModel.ContainerHeight);
    }

    export function InitializeController() {
        LoaderManager.addLoadingJob();
        if (Controller.Current) {
            Controller.Current.Stop(() => {
                Controller.Current.updateManager.clearHistory();
                Controller.Current.clearClientObjects(true);
                Controller.Current.dispose();
                Controller.Current = null;
                InitializeController();
                LoaderManager.finishLoadingJob();
            });
        } else {
            var controller = Controller.init();
            switch (window.EditorViewMode) {
                case "Anordnung":
                    controller.loadAnordnungObjects(controller.onObjectsInitialized.bind(controller));
                    //ThemeManager.ResetTheme("Anordnung");
                    break;
                case "AutoCAD":
                    controller.loadCADObjects(true, true, controller.onObjectsInitialized.bind(controller));
                    //ThemeManager.ResetTheme("AutoCAD");
                    break;
                case "Static":
                    controller.loadStaticObjects(true, true, controller.onObjectsInitialized.bind(controller));
                    //ThemeManager.ResetTheme("Static");
                    break;
                case "Electric":
                    controller.loadElectricObjects(true, controller.onObjectsInitialized.bind(controller));
                    //ThemeManager.ResetTheme("Electric");
                    break;
            }
            onWindowResize();
            LoaderManager.finishLoadingJob();
        }
    }

    export var cleanUp = window.cleanUp = () => {
        var controller = Controller.Current;
        if (controller)
            controller.dispose();
    };

    window.ReloadPositions = () => {
        Controller.Current.reloadModulePlan(true);
    };

    window.ReCreateModuleField = () => {
        Controller.Current.regenerateModulePlan(false);
    };

    window.GetModulesOnError = () => { };

    window.NavigateToOldIndex = () => {

        var loadingIcon = document.getElementById('LoadingIcon');
        if (loadingIcon && loadingIcon.style)
            loadingIcon.style.display = 'none';

        var backToOldMessageContainer = document.getElementById('ao-BackToOldMessageContainer');
        if (backToOldMessageContainer && backToOldMessageContainer.style)
            backToOldMessageContainer.style.display = 'block';

        setTimeout(() => { window.location.href = "index"; }, 5000);
        WriteTraceSimple("Go Back to old 2D-Mode ", window.EditorViewMode || "Anordnung");
    };

    //Document load funtion
    //Setup Controller, ViewModel and UpdateManager
    $(() => {
        if (window.SkipAO3DSetup)
            return;

        var browserinfo = Detector.browserInfo;
        if (browserinfo && browserinfo.name)
            WriteTraceSimple("AO 2.0 loaded - Browser: " + Detector.browserInfo.name + " v " + Detector.browserInfo.version + ", webglRenderer: " + Detector.webglParameters.webglRenderer + ", Vendor: " + Detector.webglParameters.webglVendor + " - " + Detector.webglParameters.webglVersion + ", maxTextureSize: " + Detector.webglParameters.maxTextureSize + ", supportsInstancedArrays: " + Detector.webglParameters.supportsInstancedArrays + ", GLSL: " + Detector.webglParameters.shadingLanguageVersion, window.EditorViewMode || "Anordnung");
        if (sptBrowserCompatibilityCheck() || !Detector.webgl) {

            var aoErrorMessageContainer = document.getElementById('ao-ErrorMessageContainer');
            if (aoErrorMessageContainer && aoErrorMessageContainer.style)
                aoErrorMessageContainer.style.display = 'block';

            var loadingIcon = document.getElementById('LoadingIcon');
            if (loadingIcon && loadingIcon.style)
                loadingIcon.style.display = 'none';

            setTimeout(() => { window.location.href = "index"; }, 5000);
            return;
        }
        $(window).on("unload", cleanUp);

        if ($('#versionLayoutPopUp').length)
            DManager.init('versionLayoutPopUp', 950, 'auto');
        if ($('#ao3dHelpDialog').length)
            DManager.init('ao3dHelpDialog', 600, 500);

        Controller.updateManagerInstance = new UpdateManager();

        var viewModel = Controller.viewModelInstance = new ViewModel();

        for (var name in viewModel.tools)
            viewModel.tools[name].init();

        window.addEventListener('resize', () => {
            onWindowResize();
            spt.Utils.DeferredCall("ControllerResetView", Controller.Current.resetView.bind(Controller.Current), 100);
        }, false);

        viewModel.bindToView(globalParams.ViewId);
        mapInputsFromViewmodel(viewModel);

        InitializeController();

        var $tb = $(globalParams.TBId) as any;
        if ($tb.tabs)
            $tb.tabs();
        ($tb as JQuery<HTMLElement>).find('.ui-widget-header').removeClass('ui-widget-header');
        $('.sptAnordnung').addClass('sptAnordnung3d').removeClass('sptAnordnung');

        ko.applyBindings(viewModel, $(globalParams.WrapperId).get(0));

        if ($('#_itemAnordnung').length > 0 && $('#_itemAnordnung').attr("href").indexOf("Anordnung/Edit") !== -1)
            $('#_itemAnordnung').click(switchToAnordnung as any);

        if ($('#_itemAutoCAD').length > 0 && $('#_itemAutoCAD').attr("href").indexOf("AutoCAD/Edit") !== -1)
            $('#_itemAutoCAD').click(switchToAutoCad as any);

        if ($('#_itemStatic').length > 0 && $('#_itemStatic').attr("href").indexOf("Static/Edit") !== -1)
            $('#_itemStatic').click(switchToStatic as any);

        if ($('#_itemElectric').length > 0 && $('#_itemElectric').attr("href").indexOf("Electric/Electric") !== -1)
            $('#_itemElectric').click(switchToElectric as any);

        if ($('#ScaleContainer').length)
            DManager.init('ScaleContainer', 400, "auto", SetWidthDialogTitleBarWidth, SetPaddingBack);

    });
}